import React from "react";
import { Box, IconButton,Button } from "@mui/material";
import { Row, Col,Label } from "reactstrap";
import moment from "moment";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ReactBarcode } from 'react-jsbarcode';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { MaterialReactTable, MRT_FullScreenToggleButton, MRT_ToggleDensePaddingButton } from "material-react-table";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from "@mui/icons-material/AddBox";
import CachedIcon from "@mui/icons-material/Cached";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from '@mui/icons-material/Search';
import {imgURLocalImage} from "../Componentes/Services";
import {BARCODE_IMAGE} from "./Image";
import Tooltip from '@mui/material/Tooltip';
import {STATES_DC_COMPRA,STATES_RE_RECUENTO,STATES_MOV_MOVIMIENTO} from "./States";
import {COLUMNS_RE_RECUENTO_DETALLE,COLUMNS_LISTA_COTIZACION,COLUMNS_LISTA_FPAGO,COLUMNS_LISTA_ANULACION,COLUMNS_DC_COMPRA_DETALLE,COLUMNS_RE_CUENTO,COLUMNS_DC_COMPRA,COLUMNS_MOV_MOVIMIENTO,COLUMNS_ARTICULOS,COLUMNS_AP_APARTADO,COLUMNS_CATEGORIAS,COLUMNS_USUARIOS,COLUMNS_MARCAS,COLUMNS_PROVEEDORES,COLUMNS_CLIENTES,COLUMNS_BODEGA,COLUMNS_FAC_FACTURA_DETALLE,COLUMNS_COT_COTIZACION,COLUMNS_LISTA_CLIENTE,COLUMNS_LISTA_ITEMS,COLUMNS_COTD_COOTIZACION_DETALLE } from "../Componentes/DatatableColumns";

function DATATABLE_FACTURA({deleteRow,handleSaveRow,dataFacturaDetalle,enableTab,usr_precio}){
        return <MaterialReactTable
        layoutMode="grid-no-grow" 
        enableEditing={true}
        enableColumnActions={false}
        editDisplayMode="cell"
        enableBottomToolbar={false} 
        enableTopToolbar={false}
        enableRowActions={true}
        positionActionsColumn="last"
        enablePagination={false}
        enableSorting={false}
        enableFilters={false} 
        muiTableContainerProps={{ sx: { maxHeight: '57vh' } }}
        enableStickyHeader={true}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: "Buscar",
        }}
        renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
        columns={COLUMNS_FAC_FACTURA_DETALLE(usr_precio)}
        onEditingRowSave={handleSaveRow}
        data={enableTab ? [] : dataFacturaDetalle}
        renderRowActions={({ row,table }) => (
          <Box>
            <IconButton onClick={() => table.setEditingRow(row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => deleteRow(row)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )} 
        muiTableBodyRowProps={({ row }) => {
          return {
            sx: {
              backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
            },
          };
        }}
        muiTableBodyCellProps={({ cell }) => {
            return {
              sx: {
                textAlign:cell.column.id==="mrt-row-actions"||cell.column.id==="fcd_precio"||cell.column.id==="fcd_cantidad"||cell.column.id==="fcd_itbms"||cell.column.id==="fcd_total"?"center":"left",  
              }
            };
          }}
      />
}
function DATATABLE_COTIZACION({dataCotizacion,onClickDeleteCotizacion_sts,setBasicModalEdit,setBasicModal,setFormCotCotizacion,
    fetchCotdDetalle,setFormDropDownCliente,setPrintI,onClickNuevaCotizacion,fetch_cot_cotizacion}){
    return  <MaterialReactTable
            initialState={{
              pagination: { pageSize: 50, pageIndex: 0 }
            }}
            layoutMode="grid-no-grow" 
            enableEditing={true}
            enableColumnActions={false}
            editDisplayMode="cell"
            enableBottomToolbar={true} 
            enableTopToolbar={true}
            enableRowActions={true}
            positionActionsColumn="last"
            enablePagination={true}
            enableSorting={true}
            enableFilters={true} 
            muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
            enableStickyHeader={true}
            renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            positionGlobalFilter="left"
            columns={COLUMNS_COT_COTIZACION}
            data={dataCotizacion}
            muiSearchTextFieldProps={{
              placeholder: "Buscar",
            }}
            renderRowActions={({ row }) => {
              return (
                <Box className="width-box-delete">
                  <IconButton
                    color="error"
                    onClick={() => onClickDeleteCotizacion_sts({ cot_id: row.original.cot_id, cot_status: 0 })}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setBasicModalEdit(true)
                      setBasicModal(true);
                      setFormCotCotizacion({
                          cot_id: row.original.cot_id,
                          cot_identificador: row.original.cot_identificador,
                          cot_cliente: row.original.cot_cliente,
                          cot_nombre:row.original.cli_nombre,
                          cot_fecha: moment(new Date(row.original.cot_fecha)).format("YYYY-MM-DD"),
                          cot_vendedor: row.original.cot_vendedor,
                          cot_status: row.original.cot_status,
                          cot_descripcion: row.original.cot_descripcion,
                        });
                      fetchCotdDetalle(row.original.cot_identificador);
                      setFormDropDownCliente({
                        cli_id: row.original.cot_cliente,
                        cli_nombre: row.original.cli_nombre,
                        cli_ruc:row.original.cli_ruc
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setPrintI(true);
                      setFormCotCotizacion({
                        cot_id: row.original.cot_id,
                        cot_identificador: row.original.cot_identificador,
                        cot_cliente: row.original.cot_cliente,
                        cot_nombre:row.original.cli_nombre,
                        cot_fecha: row.original.cot_fecha,
                        cot_vendedor: row.original.cot_vendedor,
                        cot_status: row.original.cot_status,
                        cot_descripcion: row.original.cot_descripcion,
                      });
                      fetchCotdDetalle(row.original.cot_identificador);
                    }}
                  >
                    <LocalPrintshopIcon />
                  </IconButton>
                  {row.original.art_img==="1"? <img className="img-art-datatable"  src ={imgURLocalImage+row.original.art_imgName} />:null}
                </Box>
              );
            }}
            muiTableBodyRowProps={({ row }) => {
              return {
                sx: {
                  backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
                },
              };
            }}
            muiTableBodyCellProps={({ cell }) => {
              return {
                sx: {
                  textAlign:cell.column.id==="mrt-row-actions"||cell.column.id==="cot_id"||cell.column.id==="cot_fecha"||cell.column.id==="cotd_total" ?"center":"left",
                }
              };
            }}

    
            renderTopToolbarCustomActions={({ table }) => (
              <Box>
                <IconButton
                  onClick={() => { onClickNuevaCotizacion() }
                }>
                  <AddBoxIcon />
                </IconButton>
                <IconButton onClick={() => fetch_cot_cotizacion()}>
                  <CachedIcon />
                </IconButton>
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_FullScreenToggleButton table={table} />
              </Box>
            )}
  />
}
function DATATABLE_LISTA_CLIENTE({dataCliente,selectCliente,setTabClientes,handleSaveRow}){
    return <MaterialReactTable 
    initialState={{
      pagination: { pageSize: 30, pageIndex: 0 }
    }}
    layoutMode="grid-no-grow" 
    enableEditing={true}
    enableColumnActions={false}
    editDisplayMode="cell"
    enableBottomToolbar={true} 
    enableTopToolbar={true}
    enableRowActions={true}
    positionActionsColumn="last"
    enablePagination={true}
    enableSorting={true}
    enableFilters={true} 
    muiTableContainerProps={{ sx: { maxHeight: '50vh' } }}
    enableStickyHeader={true}
    renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
    enableFullScreenToggle={false}
    enableDensityToggle={false}
    positionGlobalFilter="left"
    onEditingRowSave={handleSaveRow}
    columns={COLUMNS_LISTA_CLIENTE()}
    data={ dataCliente}
    muiSearchTextFieldProps={{
      placeholder: "Buscar",
    }}
    muiTableBodyRowformFactura={({ row }) => {
    return {
      sx: {
        backgroundColor:
          row.original.re_fecha_procesado !== "0000-00-00 00:00:00"
            ? "rgba(219, 237, 220, 0.78)"
            : "",
        fontWeight: "bold",
      },
    };
  }}

  muiTableBodyRowProps={({ row }) => {
    return {
      onClick: () => {
        selectCliente(row);
        setTabClientes(false)
      },
      sx: {
        backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
      },
    };
  }}
  />
}
function DATATABLE_LISTA_ITEMS({dataItems,selectItem,setTabItems}){
  return <MaterialReactTable 
  initialState={{
    pagination: { pageSize: 30, pageIndex: 0 }
  }}
    layoutMode="grid-no-grow" 
    enableEditing={false}
    enableColumnActions={false}
    editDisplayMode="cell"
    enableBottomToolbar={true} 
    enableTopToolbar={true}
    enableRowActions={true}
    positionActionsColumn="last"
    enablePagination={true}
    enableSorting={true}
    enableFilters={true} 
    muiTableContainerProps={{ sx: { maxHeight: '50vh' } }}
    enableStickyHeader={true}
    renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
    enableFullScreenToggle={false}
    enableDensityToggle={false}
    positionGlobalFilter="left"
    columns={COLUMNS_LISTA_ITEMS()}
    data={ dataItems}
    muiSearchTextFieldProps={{
      placeholder: "Buscar",
    }}
    muiTableBodyRowformFactura={({ row }) => {
    return {
      sx: {
        backgroundColor:
          row.original.re_fecha_procesado !== "0000-00-00 00:00:00"
            ? "rgba(219, 237, 220, 0.78)"
            : "",
        fontWeight: "bold",
      },
    };
  }}
  muiTableBodyRowProps={({ row }) => {
    return {
      onClick: () => {
        selectItem(row.original.art_upc);
        setTabItems(false)
      },
      sx: {
        backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
      },
    };
  }}

  />
}
function DATATABLE_COTIZACION_DETALLE({enableTab,selectItem,deleteRow,OBJCotizacionDetalle,handleSaveRow,usr_precio}){
  return <MaterialReactTable
  initialState={{
    pagination: { pageSize: 30, pageIndex: 0 }
  }}
  layoutMode="grid-no-grow" 
  enableEditing={true}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={false} 
  enableTopToolbar={false}
  enableRowActions={true}
  positionActionsColumn="first"
  enablePagination={true}
  enableSorting={true}
  onEditingRowSave={handleSaveRow}
  enableFilters={true} 
  muiTableContainerProps={{ sx: { maxHeight: '45vh' } }}
  enableStickyHeader={true}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  enableFullScreenToggle={false}
  enableDensityToggle={false}
  positionGlobalFilter="left"
  columns={COLUMNS_COTD_COOTIZACION_DETALLE(usr_precio)}
  data={enableTab?[]:OBJCotizacionDetalle}
  muiSearchTextFieldProps={{
    placeholder: "Buscar",
  }}
  // muiTableBodyRowProps={({ row }) => {
  //   return {
  //     onClick: () => {
  //       selectItem({art_upc:row.original.art_upc})
  //     },
  //     sx: {
  //       backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
  //     },
  //   };
  // }}
  muiTableBodyCellProps={({ cell }) => {
      return {
        sx: {
          textAlign:cell.column.id==="mrt-row-actions"||cell.column.id==="art_upc"
          ||cell.column.id==="cotd_precio"||cell.column.id==="cotd_cantidad"||cell.column.id==="cotd_itbms"||cell.column.id==="cotd_total" ?"center":"left",
        }
      };
    }}
  renderRowActions={({ row,table }) => {
   return <Box className="width-box-delete-cotizacion mx-auto d-flex">
      <IconButton onClick={() => table.setEditingRow(row)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => deleteRow(row)}>
        <DeleteIcon />
      </IconButton>
      {row.original.art_img==="1"? <img className="img-art-datatable"  src ={imgURLocalImage+row.original.art_imgName} />:null}
    </Box>
  }
    
  } />
}
function DATATABLE_BODEGAS({dataBodegas,setBasicModalDelete,setFormBodegas,setBasicModal,setBasicModalEdit,setError,fetchBodega}){
  return <MaterialReactTable
          initialState={{
            pagination: { pageSize: 50, pageIndex: 0 }
          }}
          layoutMode="grid-no-grow" 
          enableEditing={true}
          enableColumnActions={false}
          editDisplayMode="cell"
          enableBottomToolbar={true} 
          enableTopToolbar={true}
          enableRowActions={true}
          positionActionsColumn="last"
          enablePagination={true}
          enableSorting={true}
          enableFilters={true} 
          muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
          enableStickyHeader={true}
          renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          positionGlobalFilter="left"
          columns={COLUMNS_BODEGA}
          data={dataBodegas}
          muiSearchTextFieldProps={{
            placeholder: "Buscar",
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              sx: {
                backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
              },
            };
          }}
          muiTableBodyCellProps={({ cell }) => {
              return {
                sx: {
                  textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="bod_ubicacion"?"center":"left",  
                }
              };
            }}
          renderRowActions={({ row }) => {
            return (
              <Box className="w-90px mx-auto">
                <IconButton
                    color="error"
                    onClick={() =>{
                      setBasicModalDelete(true);
                      setFormBodegas(row.original)
                    }
                    
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                <IconButton
                  onClick={() => {
                    setBasicModal(true);
                    setFormBodegas(row.original);
                    setBasicModalEdit(true);
                    setError("");
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <IconButton>
                <AddBoxIcon
                  onClick={() => {
                    setBasicModal(true);
                    setFormBodegas({
                      bod_id: "",
                      bod_nombre: "",
                      bod_ubicacion: "",
                      bod_sts: "",
                    });
                    setBasicModalEdit(false)
                  }}
                />
              </IconButton>
              <IconButton>
                <CachedIcon onClick={() => fetchBodega()} />
              </IconButton>

              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </Box>
          )}
        />;
}
function DATATABLE_USUARIOS({dataUsuarios,setBasicModalDelete,setFormUsuarios,setBasicModal,setBasicModalEdit,setError,fetchUsuarios,isLoadingU}){
  return <MaterialReactTable
          initialState={{
            pagination: { pageSize: 50, pageIndex: 0 }
          }}
          state={{ isLoading: isLoadingU }}
          layoutMode="grid-no-grow" 
          enableEditing={true}
          enableColumnActions={false}
          editDisplayMode="cell"
          enableBottomToolbar={true} 
          enableTopToolbar={true}
          enableRowActions={true}
          positionActionsColumn="last"
          enablePagination={true}
          enableSorting={true}
          enableFilters={true} 
          muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
          enableStickyHeader={true}
          renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          positionGlobalFilter="left"
          columns={COLUMNS_USUARIOS}
          data={dataUsuarios}
          muiSearchTextFieldProps={{
            placeholder: "Buscar",
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              sx: {
                backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
              },
            };
          }}
          muiTableBodyCellProps={({ cell }) => {
              return {
                sx: {
                  textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="bod_ubicacion"|| cell.column.id==="usr_perfil"?"center":"left",  
                }
              };
            }}
            renderRowActions={({ row }) => {
              return (
                <Box className="w-90px mx-auto">
                  <IconButton
                      color="error"
                      onClick={() =>{
                        setBasicModalDelete(true);
                        setFormUsuarios(row.original)
                      }
                       
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  <IconButton
                    onClick={() => {
                      setBasicModal(true);
                      setFormUsuarios(row.original);
                      setBasicModalEdit(true);
                      setError("");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              );
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box>
                {/* <IconButton
                  onClick={() => {
                    setBasicModal(true);
                    setFormUsuarios({
                      usr_id: "",
                      usr_usuario: "",
                      usr_clave: "",
                      usr_nombre: "",
                      usr_perfil: "",
                      usr_sucursal: "",
                      usr_print:"2"
                    });
                    setBasicModalEdit(false)
                  }}
                >
                  <AddBoxIcon />
                </IconButton> */}
                <IconButton onClick={() => fetchUsuarios()}>
                  <CachedIcon />
                </IconButton>

                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_FullScreenToggleButton table={table} />
              </Box>
            )}
        />;
}
function DATATABLE_CLIENTES({dataClientes,setBasicModalDelete,setFormClientes,setBasicModal,setBasicModalEdit,setError,fetchCliente, isLoadingC}){
  return <MaterialReactTable
      initialState={{
        pagination: { pageSize: 30, pageIndex: 0 }
      }}
        layoutMode="grid-no-grow" 
        enableEditing={true}
        state={{ isLoading: isLoadingC }}
        enableColumnActions={false}
        editDisplayMode="cell"
        enableBottomToolbar={true} 
        enableTopToolbar={true}
        enableRowActions={true}
        positionActionsColumn="last"
        enablePagination={true}
        enableSorting={true}
        enableFilters={true} 
        muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
        enableStickyHeader={true}
        renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        positionGlobalFilter="left"
        columns={COLUMNS_CLIENTES}
        data={ dataClientes}
        muiSearchTextFieldProps={{
          placeholder: "Buscar",
        }}
        muiTableBodyRowProps={({ row }) => {
          return {
            sx: {
              backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
            },
          };
        }}
        muiTableBodyCellProps={({ cell }) => {
            return {
              sx: {
                textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="cli_telefono"|| cell.column.id==="cli_ruc"?"center":"left",  
              }
            };
          }}
      renderRowActions={({ row }) => {
        return (
          <Box className="w-90px mx-auto">
          <IconButton
                color="error"
                onClick={() =>{
                  setBasicModalDelete(true);
                  setFormClientes(row.original)
                }
                
                }
              >
                <DeleteIcon />
              </IconButton>
            <IconButton
              onClick={() => {
                setBasicModal(true);
                setFormClientes(row.original);
                setBasicModalEdit(true);
                setError("");
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        );
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box>
          <IconButton
            onClick={() => {
              setBasicModal(true);
              setFormClientes({
                cli_id: "",
                cli_suc: "",
                cli_sts: "",
                cli_nombre: "",
                cli_direccion: "",
                cli_telefono: "",
                cli_telefono2: "",
                cli_ruc: "",
                cli_dv: "",
                cli_email: "",
              });
              setBasicModalEdit(false)
            }}
          >
            <AddBoxIcon />
          </IconButton>
          <IconButton onClick={() => fetchCliente()}>
            <CachedIcon />
          </IconButton>

          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_FullScreenToggleButton table={table} />
        </Box>
      )}
    />
}
function DATATABLE_PROVEEDORES({dataProveedores,setBasicModalDelete,setFormProveedores,setBasicModal,setBasicModalEdit,setError,fetchProveedores,isLoadingP}){
  return <MaterialReactTable
      initialState={{
        pagination: { pageSize: 30, pageIndex: 0 }
      }}
        layoutMode="grid-no-grow" 
        enableEditing={true}
        enableColumnActions={false}
        editDisplayMode="cell"
        state={{ isLoading: isLoadingP }}
        enableBottomToolbar={true} 
        enableTopToolbar={true}
        enableRowActions={true}
        positionActionsColumn="last"
        enablePagination={true}
        enableSorting={true}
        enableFilters={true} 
        muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
        enableStickyHeader={true}
        renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        positionGlobalFilter="left"
        columns={COLUMNS_PROVEEDORES}
        data={ dataProveedores}
        muiSearchTextFieldProps={{
          placeholder: "Buscar",
        }}
        muiTableBodyRowProps={({ row }) => {
          return {
            sx: {
              backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
            },
          };
        }}
        muiTableBodyCellProps={({ cell }) => {
            return {
              sx: {
                textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="prov_ruc"|| cell.column.id==="prov_telefono"?"center":"left",  
              }
            };
          }}
          renderRowActions={({ row }) => {
            return (
              <Box className="w-90px mx-auto">
                <IconButton
                    color="error"
                    onClick={() =>{
                      setBasicModalDelete(true);
                      setFormProveedores(row.original)
                    }
                     
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                <IconButton
                  onClick={() => {
                    setBasicModal(true);
                    setFormProveedores(row.original);
                    setBasicModalEdit(true);
                    setError("");
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <IconButton
                onClick={() => {
                  setBasicModal(true);
                  setFormProveedores({
                    prov_id: "",
                    prov_suc: "",
                    prov_nombre: "",
                    prov_direccion: "",
                    prov_telefono: "",
                    prov_ruc: "",
                    prov_dv: "",
                    prov_sts: "",
                    prov_email: "",
                  });
                  setBasicModalEdit(false)
                }}
              >
                <AddBoxIcon />
              </IconButton>
              <IconButton onClick={() => fetchProveedores()}>
                <CachedIcon />
              </IconButton>

              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </Box>
          )}
    />
}
function DATATABLE_MARCAS({dataMarcas,setBasicModalDelete,setFormMarcas,setBasicModal,setBasicModalEdit,setError,fetchMarcas,isLoadingM}){
  return <MaterialReactTable
      initialState={{
        pagination: { pageSize: 30, pageIndex: 0 }
      }}
        layoutMode="grid-no-grow" 
        enableEditing={true}
        enableColumnActions={false}
        editDisplayMode="cell"
        state={{ isLoading: isLoadingM }}
        enableBottomToolbar={true} 
        enableTopToolbar={true}
        enableRowActions={true}
        positionActionsColumn="last"
        enablePagination={true}
        enableSorting={true}
        enableFilters={true} 
        muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
        enableStickyHeader={true}
        renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        positionGlobalFilter="left"
        columns={COLUMNS_MARCAS}
        data={ dataMarcas}
        muiSearchTextFieldProps={{
          placeholder: "Buscar",
        }}
        muiTableBodyRowProps={({ row }) => {
          return {
            sx: {
              backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
            },
          };
        }}
        muiTableBodyCellProps={({ cell }) => {
            return {
              sx: {
                textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="prov_ruc"|| cell.column.id==="prov_telefono"?"center":"left",  
              }
            };
          }}
          renderRowActions={({ row }) => {
            return (
              <Box className="w-90px mx-auto">
                <IconButton
                    color="error"
                    onClick={() =>{
                      setBasicModalDelete(true);
                      setFormMarcas(row.original)
                    }
                     
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                <IconButton
                  onClick={() => {
                    setBasicModal(true);
                    setFormMarcas(row.original);
                    setBasicModalEdit(true);
                    setError("");
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <IconButton
                onClick={() => {
                  setBasicModal(true);
                  setFormMarcas({
                    mar_id: "",
                    mar_descripcion: "",
                    mar_sts: "",
                  });
                  setBasicModalEdit(false)
                }}
              >
                <AddBoxIcon />
              </IconButton>
              <IconButton onClick={() => fetchMarcas()}>
                <CachedIcon />
              </IconButton>

              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </Box>
          )}
    />
}
function DATATABLE_CATEGORIAS({dataCategorias,setBasicModalDelete,setFormCategorias,setBasicModal,setBasicModalEdit,setError,fetchCategoria,isLoadingCa}){
  return <MaterialReactTable
      initialState={{
        pagination: { pageSize: 30, pageIndex: 0 }
      }}
        layoutMode="grid-no-grow" 
        enableEditing={true}
        enableColumnActions={false}
        editDisplayMode="cell"
        state={{ isLoading: isLoadingCa }}
        enableBottomToolbar={true} 
        enableTopToolbar={true}
        enableRowActions={true}
        positionActionsColumn="last"
        enablePagination={true}
        enableSorting={true}
        enableFilters={true} 
        muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
        enableStickyHeader={true}
        renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        positionGlobalFilter="left"
        columns={COLUMNS_CATEGORIAS}
        data={dataCategorias}
        muiSearchTextFieldProps={{
          placeholder: "Buscar",
        }}
        muiTableBodyRowProps={({ row }) => {
          return {
            sx: {
              backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
            },
          };
        }}
          renderRowActions={({ row }) => {
            return (
              <Box className="w-90px mx-auto">
                <IconButton
                    color="error"
                    onClick={() =>{
                      setBasicModalDelete(true);
                      setFormCategorias(row.original)
                    }
                     
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                <IconButton
                  onClick={() => {
                    setBasicModal(true);
                    setFormCategorias(row.original);
                    setBasicModalEdit(true);
                    setError("");
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <IconButton
                onClick={() => {
                  setBasicModal(true);
                  setFormCategorias({
                    cat_id: "",
                    cat_descripcion: "",
                    cat_sts: "",
                  });
                  setBasicModalEdit(false)
                }}
              >
                <AddBoxIcon />
              </IconButton>
              <IconButton onClick={() => fetchCategoria()}>
                <CachedIcon />
              </IconButton>

              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </Box>
          )}
    />
}
function DATATABLE_APARTADO({dataapartado,onClickUpdateapartado_sts,setFormapartado,setBasicModal,
  setBasicModalEdit,setOBJapartadoDetalle,fetchDCapartadoDetalle,setFormapartadoDetalle,fetchDCapartado,setPrintI}){
  return <MaterialReactTable
  initialState={{
    pagination: { pageSize: 50, pageIndex: 0 }
  }}
  layoutMode="grid-no-grow" 
  enableEditing={true}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={true} 
  enableTopToolbar={true}
  enableRowActions={true}
  positionActionsColumn="last"
  enablePagination={true}
  enableSorting={true}
  enableFilters={true} 
  muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
  enableStickyHeader={true}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  enableFullScreenToggle={false}
  enableDensityToggle={false}
  positionGlobalFilter="left"
  columns={COLUMNS_AP_APARTADO}
  data={dataapartado}
  muiSearchTextFieldProps={{
    placeholder: "Buscar",
  }}
  muiTableBodyRowProps={({ row }) => {
    return {
      sx: {
        backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
      },
    };
  }}
  renderRowActions={({ row }) => {
    return (
      <Box className="width-box-delete mx-auto">
        <IconButton
          color="error"
          onClick={() =>
            onClickUpdateapartado_sts({ ap_id: row.original.ap_id, ap_status: 0 })
          }
        >
          <DeleteIcon />
        </IconButton>
        {
          row.original.ap_procesado ==="1"?null:
          <IconButton

          onClick={() => {
            setBasicModalEdit(true)
            setBasicModal(true);
            setFormapartado({
              ap_id: row.original.ap_id,
              ap_docn: row.original.ap_docn,
              ap_identificador: row.original.ap_identificador,
              ap_proveedor: row.original.ap_proveedor,
              prov_proveedor: row.original.prov_proveedor,
              ap_procesado: row.original.ap_procesado,
              apd_total: row.apd_total,
              ap_fecha_procesado: row.original.ap_fecha_procesado,
              ap_fecha: row.original.ap_fecha,
              ap_descripcion: row.original.ap_descripcion,
              ap_status: row.original.ap_status,
            });
            fetchDCapartadoDetalle(row.original.ap_identificador);
            setBasicModalEdit(true);
          }}
        >
          <EditIcon />
        </IconButton>
        }
       
        <IconButton
          onClick={() => {
            setPrintI(true);
            setFormapartado({
              ap_id: row.original.ap_id,
              ap_docn: row.original.ap_docn,
              ap_identificador: row.original.ap_identificador,
              ap_proveedor: row.original.ap_proveedor,
              prov_proveedor: row.original.prov_proveedor,
              ap_procesado: row.original.ap_procesado,
              apd_total: row.apd_total,
              ap_fecha_procesado: row.original.ap_fecha_procesado,
              ap_fecha: row.original.ap_fecha,
              ap_descripcion: row.original.ap_descripcion,
              ap_status: row.original.ap_status,
            });
            fetchDCapartadoDetalle(row.original.ap_identificador);
          }}
        >
          <LocalPrintshopIcon />
        </IconButton>
      </Box>
    );
  }}
  renderTopToolbarCustomActions={({ table }) => (
    <Box>
      <IconButton
        onClick={() => {
          setBasicModalEdit(false)
          setBasicModal(true);
          setFormapartado({
            ap_id: "",
            ap_docn: "",
            ap_identificador: moment(new Date()).format("YYYYMMDDHHmmss"),
            ap_suc: "",
            ap_proveedor: "",
            ap_procesado: "",
            apd_total: "",
            prov_proveedor: "",
            ap_fecha_procesado: "",
            ap_fecha: moment(new Date()).format("YYYY-MM-DD"),
            ap_descripcion: "",
            ap_status: "",
          });
          setFormapartadoDetalle({
            apd_id: "",
            apd_articulo: "",
            apd_precio: "",
            apd_cantidad: "1",
            apd_itbms: "",
            apd_descuento: "",
            apd_total: "",
            apd_descuento_number: "",
            art_descripcion: "",
            art_upc:""
          });
          setOBJapartadoDetalle([]);
        }}
      >
        <AddBoxIcon />
      </IconButton>
      <IconButton onClick={() => fetchDCapartado()}>
        <CachedIcon />
      </IconButton>
      <MRT_ToggleDensePaddingButton table={table} />
      <MRT_FullScreenToggleButton table={table} />
    </Box>
  )}
/>
}
function DATATABLE_ARTICULO({dataArticulos,setBasicModalDelete,setBasicModalEdit,setBasicModal,setFormArticulos,
  setImages,fetchArticulos,setFormDropDown,fetchBodegaByArticulo,setError,setDataArticuloBodega,setSrcImage,setModalImageTooltip,setImgName,setPrintTooltip,
  handleExportData,handleExportRows}){
  return  <MaterialReactTable
          initialState={{
            pagination: { pageSize: 50, pageIndex: 0 }
          }}
          layoutMode="grid-no-grow" 
          enableEditing={true}
          enableColumnActions={false}
          editDisplayMode="cell"
          enableBottomToolbar={true} 
          enableTopToolbar={true}
          enableRowActions={true}
          positionActionsColumn="first"
          enablePagination={true}
          enableSorting={true}
          enableFilters={true} 
          muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
          enableStickyHeader={true}
          renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          positionGlobalFilter="left"
          columns={COLUMNS_ARTICULOS}
          data={dataArticulos}
          muiSearchTextFieldProps={{
            placeholder: "Buscar",
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              sx: {
                backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
              },
            };
          }}
          // displayColumnDefOptions= { 'mrt-row-actions': { size: 300 } },
          // displayColumnDefOptions= {
          //   'mrt-row-actions"= {
          //     header= 'Change Account Settings', //change header text
          //     size: 300, //make actions column wider
          //   }
          // }
       muiTableBodyProps={{
            sx: () => ({
              '& tr:nth-of-type(odd)': {
              }
            })
          }}
          renderRowActions={({ row }) => {
            return (
              <Box className="width-box-delete-articulos mx-auto d-flex">
                <IconButton
                  color="error"
                  onClick={() => {
                    setBasicModalDelete(true);
                    setFormArticulos(row.original);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setBasicModal(true);
                    setFormArticulos(row.original);
                    setImages([]);
                    fetchBodegaByArticulo(row.original.art_id);
                    setBasicModalEdit(true);
                    setFormDropDown({
                      mar_id: "",
                      mar_descripcion: "",
                      cat_id: "",
                      cat_descripcion: "",
                      prov_id: "",
                      prov_nombre: "",
                      bod_id: "",
                      bod_nombre: "",
                    });
                    setFormDropDown({
                      mar_id: row.original.art_marca,
                      mar_descripcion: row.original.mar_descripcion,
                      cat_id: row.original.art_categoria,
                      cat_descripcion: row.original.cat_descripcion,
                      prov_id: row.original.art_proveedor,
                      prov_nombre: row.original.prov_nombre,
                      itbm_cod_venta: row.original.art_itbm_cod_v,
                      itbm_porc_v: row.original.art_itbm_venta,
                      itbm_cod_compra: row.original.art_itbm_cod_c,
                      itbm_porc_c: row.original.art_itbm_compra,
                    });
                    setError("");
                  }}
                >
                  <EditIcon />
                </IconButton>
                <img src={BARCODE_IMAGE} className="img-barcode" onClick={() => {setPrintTooltip(true); setFormArticulos(row.original)}}/>
                {row.original.art_img==="1"? <img className="img-art-datatable"  onClick={() => {setImgName(row.original.art_upc);setSrcImage(imgURLocalImage+row.original.art_imgName);setModalImageTooltip(true) }}src ={imgURLocalImage+row.original.art_imgName} />:null}
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <IconButton
                onClick={() => {
                  setBasicModal(true);
                  setDataArticuloBodega([]);
                  setImages([]);
                  setBasicModalEdit(false);
                  setFormDropDown({
                    mar_id: "",
                    mar_descripcion: "",
                    cat_id: "",
                    cat_descripcion: "",
                    prov_id: "",
                    prov_nombre: "",
                    bod_id: "",
                    bod_nombre: "",
                  });
                  setFormArticulos({
                    art_id: "",
                    art_upc: "",
                    art_sucursal: "",
                    art_categoria: "",
                    art_proveedor: "",
                    art_marca: "",
                    art_descripcion: "",
                    art_costo: "",
                    art_precio: "",
                    art_precio2: "",
                    art_precio3: "",
                    art_referencia: "",
                    art_linea_roja: "",
                    art_img: "",
                    art_imgdataurl: "",
                    art_imgType: "",
                    art_imgName: "",
                    art_serie:"",
                    art_itbm_venta: "",
                    art_itbm_compra: "",
                    art_sts: "",
                    inv_id:"",
                    inv_qty:""
                  });
                  
                }}
              >
                <AddBoxIcon />
              </IconButton>
              <IconButton onClick={() => fetchArticulos()}>
                <CachedIcon />
              </IconButton>
              <Button
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  onClick={handleExportData}
                  startIcon={<FileDownloadIcon />}
                >
                  CSV
                </Button>
                {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button> */}
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </Box>
          )}

/>
}
function DATATABLE_MOVIMIENTOS({dataMovimiento,setTabMovientoDetail,fetchMovimientoDetallado}){
  return  <MaterialReactTable
          initialState={{
            pagination: { pageSize: 50, pageIndex: 0 }
          }}
          layoutMode="grid-no-grow" 
          enableEditing={true}
          enableColumnActions={false}
          editDisplayMode="cell"
          enableBottomToolbar={true} 
          enableTopToolbar={true}
          enableRowActions={true}
          positionActionsColumn="last"
          enablePagination={true}
          enableSorting={true}
          enableFilters={true} 
          muiTableContainerProps={{ sx: { maxHeight: '65vh' } }}
          enableStickyHeader={true}
          renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          positionGlobalFilter="left"
          columns={COLUMNS_MOV_MOVIMIENTO}
          data={dataMovimiento}
          muiSearchTextFieldProps={{
            placeholder: "Buscar",
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              sx: {
                backgroundColor:Number(row.original.inv_qty) > 0 ? "#f5f5f5": "#FF6565",
                color:Number(row.original.inv_qty) > 0 ? "#000000": "#FFFFFF",
                
              },
            };
          }}
          muiTableBodyCellProps={({ cell }) => {
            return {
              sx: {
                textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="art_categoria"|| cell.column.id==="art_marca"
                || cell.column.id==="art_linea_roja"|| cell.column.id==="inv_qty"?"center":"left",  
              }
            };
          }}
          renderRowActions={({ row }) => {
            return (
              <Box className="width-box-deletes">
                {
                  <IconButton onClick={() => {
                    fetchMovimientoDetallado({
                      art_id: row.original.art_id
                    });
                    setTabMovientoDetail(true)
                  }}>
                  <SearchIcon/>
                </IconButton>
                }

              </Box>
            );
          }}

/>
}
function DATATABLE_DC_COMPRA({dataCompra,setFormCompraDelete,setBasicModalDelete,
  setBasicModalEdit,setBasicModal,setFormCompra,fetchDCCompraDetalle,setFormDropDownProveedor,setPrintI,setFormProcess,setBasicModalProcesar,
  fetchDCCompra,setFormCompraDetalle, setOBJCompraDetalle={setOBJCompraDetalle}}){
  return  <MaterialReactTable
          initialState={{
            pagination: { pageSize: 50, pageIndex: 0 }
          }}
          layoutMode="grid-no-grow" 
          enableEditing={true}
          enableColumnActions={false}
          editDisplayMode="cell"
          enableBottomToolbar={true} 
          enableTopToolbar={true}
          enableRowActions={true}
          positionActionsColumn="last"
          enablePagination={true}
          enableSorting={true}
          enableFilters={true} 
          muiTableContainerProps={{ sx: { maxHeight: '65vh' } }}
          muiTableBodyCellProps={({ cell }) => {
            return {
              sx: {
                textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="dc_fecha"|| cell.column.id==="dc_fecha_procesado"?"center":"left",  
              }
            };
          }}
          enableStickyHeader={true}
          renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          positionGlobalFilter="left"
          columns={COLUMNS_DC_COMPRA}
          data={dataCompra}
          muiSearchTextFieldProps={{
            placeholder: "Buscar",
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              sx: {
                backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
              },
            };
          }}
          renderRowActions={({ row }) => {
            return (
              <Box className="width-box-delete2 mx-auto">
                <IconButton
                  color="error"
                  onClick={() =>{
                    // onClickUpdateCompra_sts({ dc_id: row.original.dc_id, dc_status: 0 })
                    setFormCompraDelete({ dc_id: row.original.dc_id, dc_status: 0 })
                    setBasicModalDelete(true)
                  }

                  }
                >
                  <DeleteIcon />
                </IconButton>
                {
                  row.original.dc_procesado ==="1"?null:
                  <IconButton

                  onClick={() => {
                    setBasicModalEdit(true)
                    setBasicModal(true);
                    setFormCompra({
                      dc_id: row.original.dc_id,
                      dc_docn: row.original.dc_docn,
                      dc_identificador: row.original.dc_identificador,
                      dc_proveedor: row.original.dc_proveedor,
                      prov_proveedor: row.original.prov_proveedor,
                      dc_procesado: row.original.dc_procesado,
                      dcd_total: row.dcd_total,
                      dc_fecha_procesado: row.original.dc_fecha_procesado,
                      dc_fecha: row.original.dc_fecha,
                      dc_descripcion: row.original.dc_descripcion,
                      dc_status: row.original.dc_status,
                    });
                    fetchDCCompraDetalle(row.original.dc_identificador);
                    setBasicModalEdit(true);
                    setFormDropDownProveedor({
                      prov_id: row.original.dc_proveedor,
                      prov_nombre: row.original.prov_proveedor,
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
                }
               
                <IconButton
                  onClick={() => {
                    setPrintI(true);
                    setFormCompra({
                      dc_id: row.original.dc_id,
                      dc_docn: row.original.dc_docn,
                      dc_identificador: row.original.dc_identificador,
                      dc_proveedor: row.original.dc_proveedor,
                      prov_proveedor: row.original.prov_proveedor,
                      dc_procesado: row.original.dc_procesado,
                      dcd_total: row.dcd_total,
                      dc_fecha_procesado: row.original.dc_fecha_procesado,
                      dc_fecha: row.original.dc_fecha,
                      dc_descripcion: row.original.dc_descripcion,
                      dc_status: row.original.dc_status,
                    });
                    fetchDCCompraDetalle(row.original.dc_identificador);
                  }}
                >
                  <LocalPrintshopIcon />
                </IconButton>
                {
                  row.original.dc_procesado ==="1"?null:                    <IconButton                       onClick={() => {
                    setFormProcess({
                      dcd_identificador: row.original.dc_identificador,
                      dc_proveedor:row.original.dc_proveedor,
                      dc_id: row.original.dc_id,
                    });
                    setBasicModalProcesar(true)
                  }}>
                  <FlipCameraAndroidIcon/>
                </IconButton>
                }

              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <IconButton
                onClick={() => {
                  setBasicModalEdit(false)
                  setBasicModal(true);
                  setFormDropDownProveedor({
                    prov_id: "",
                    prov_nombre: "",
                  });
                  setFormCompra(STATES_DC_COMPRA({staneName:"formCompra"}));
                  setFormCompraDetalle(STATES_DC_COMPRA({staneName:"formCompraDetalle"}));
                  setOBJCompraDetalle([]);
                }}
              >
                <AddBoxIcon />
              </IconButton>
              <IconButton onClick={() => fetchDCCompra()}>
                <CachedIcon />
              </IconButton>
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </Box>
          )}

/>
}
function DATATABLE_INV_INVENTARIO({dataRecuento,setFormRecuentoDelete,setBasicModalDeleteRe,
  fetchRecuentoDetalle,setFormRecuento,setBasicModalRe,setBasicModalEditRe,setPrintI,setFormProcessRe,setBasicModalProcesarRe,
  setOBJRecuentoDetalle,fetchRecuento,setFormRecuentoDetalle}){
  return  <MaterialReactTable
  initialState={{
    pagination: { pageSize: 50, pageIndex: 0 }
  }}
  layoutMode="grid-no-grow" 
  enableEditing={true}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={true} 
  enableTopToolbar={true}
  enableRowActions={true}
  positionActionsColumn="last"
  enablePagination={true}
  enableSorting={false}
  enableFilters={false} 
  muiTableContainerProps={{ sx: { maxHeight: '65vh' } }}
  muiTableBodyCellProps={({ cell }) => {
    return {
      sx: {
        textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="re_fecha"|| cell.column.id==="re_fecha_procesado"?"center":"left",  
      }
    };
  }}
  enableStickyHeader={true}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  enableFullScreenToggle={false}
  enableDensityToggle={false}
  positionGlobalFilter="left"

  muiTableBodyRowProps={({ row }) => {
    return {
      sx: {
        backgroundColor:
          row.original.re_fecha_procesado !== "0000-00-00 00:00:00"
            ? "rgba(219, 237, 220, 0.78)"
            : "",
        fontWeight: "bold",
        height:"60px"
      },
    };
  }}

  columns={COLUMNS_RE_CUENTO}
  data={dataRecuento}
  muiSearchTextFieldProps={{
    placeholder: "Buscar",
  }}
  renderRowActions={({ row }) => {
    return (
      <Box className="width-box-delete">
        <IconButton
          color="error"
          onClick={() =>{
            setFormRecuentoDelete({ re_id: row.original.re_id, re_status: 0 });
            setBasicModalDeleteRe(true)
          }

          }
        >
          <DeleteIcon />
        </IconButton>
        {
          row.original.re_procesado ==="1"?null:
          <IconButton

          onClick={() => {
            setBasicModalEditRe(true)
            setBasicModalRe(true);
            setFormRecuento({
              re_id: row.original.re_id,
              re_identificador: row.original.re_identificador,
              re_fecha:moment(row.original.re_fecha).format("YYYY-MM-DD") ,
              re_fecha_procesado:row.original.re_fecha_procesado,
              re_procesado:row.original.re_procesado,
              re_descripcion: row.original.re_descripcion,
            });
            fetchRecuentoDetalle({re_identificador:row.original.re_identificador});
          }}
        >
          <EditIcon />
        </IconButton>
        }
       
        <IconButton
          onClick={() => {
            setPrintI(true);
            setFormRecuento({
              re_id: row.original.re_id,
              re_identificador: row.original.re_identificador,
              re_fecha:row.original.re_fecha,
              re_fecha_procesado:row.original.re_fecha_procesado,
              re_procesado:row.original.re_procesado,
              re_descripcion: row.original.re_descripcion,
            });
            fetchRecuentoDetalle({re_identificador:row.original.re_identificador});
          }}
        >
          <LocalPrintshopIcon />
        </IconButton>
        {
          row.original.re_procesado ==="1"?null:                    <IconButton                       onClick={() => {
            setFormProcessRe({
              red_identificador: row.original.re_identificador,
              re_id: row.original.re_id,
            });
            setBasicModalProcesarRe(true)
          }}>
          <FlipCameraAndroidIcon/>
        </IconButton>
        }

      </Box>
    );
  }}
  renderTopToolbarCustomActions={({ table }) => (
    <Box>
      <IconButton
        onClick={() => {
          setBasicModalEditRe(false)
          setBasicModalRe(true);
          setFormRecuentoDetalle(STATES_RE_RECUENTO({staneName:"formRecuentoDetalle"}));
          setFormRecuento(STATES_RE_RECUENTO({staneName:"formRecuento"}));
          setOBJRecuentoDetalle([]);
        }}
      >
        <AddBoxIcon />
      </IconButton>
      <IconButton onClick={() => fetchRecuento()}>
        <CachedIcon />
      </IconButton>
      <MRT_ToggleDensePaddingButton table={table} />
      <MRT_FullScreenToggleButton table={table} />
    </Box>
  )}

/>
}

function DATATABLE_LISTA_ITEMS2({dataItems}){
  return  <MaterialReactTable
  initialState={{
    pagination: { pageSize: 50, pageIndex: 0 }
  }}
  layoutMode="grid-no-grow" 
  enableEditing={true}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={true} 
  enableTopToolbar={true}
  enableRowActions={true}
  positionActionsColumn="last"
  enablePagination={true}
  enableSorting={true}
  enableFilters={true} 
  muiTableContainerProps={{ sx: { maxHeight: '65vh' } }}
  muiTableBodyCellProps={({ cell }) => {
    return {
      sx: {
        textAlign: cell.column.id==="mrt-row-actions"|| cell.column.id==="re_fecha"|| cell.column.id==="re_fecha_procesado"?"center":"left",  
      }
    };
  }}
  enableStickyHeader={true}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  enableFullScreenToggle={false}
  enableDensityToggle={false}
  positionGlobalFilter="left"

  muiTableBodyRowProps={({ row }) => {
    return {
      sx: {
        backgroundColor:
          row.original.re_fecha_procesado !== "0000-00-00 00:00:00"
            ? "rgba(219, 237, 220, 0.78)"
            : "",
        fontWeight: "bold",
        height:"60px"
      },
    };
  }}

  columns={COLUMNS_LISTA_ITEMS}
  data={dataItems}
  muiSearchTextFieldProps={{
    placeholder: "Buscar",
  }}
 
/>
}
function DATATABLE_DC_COMPRA_DETALLE({dataCompraDetalle,deleteRow,onBlurs,enableTab,handleSaveRow}){
  return <MaterialReactTable
  initialState={{
    pagination: { pageSize: 50, pageIndex: 0 }
  }}
  layoutMode="grid-no-grow" 
  enableEditing={true}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={true} 
  enableTopToolbar={false}
  enableRowActions={true}
  positionActionsColumn="last"
  enablePagination={true}
  enableSorting={false}
  enableFilters={false} 
  muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
  enableStickyHeader={true}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  enableFullScreenToggle={false}
  enableDensityToggle={false}
  positionGlobalFilter="left"
  muiSearchTextFieldProps={{
    placeholder: "Buscar",
  }}
  muiTableBodyRowProps={({ row }) => {
    return {
      sx: {
        backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
      },
    };
  }}
muiTableBodyProps={{
    sx: () => ({
      '& tr:nth-of-type(odd)': {
      }
    })
  }}
  columns={COLUMNS_DC_COMPRA_DETALLE(deleteRow,onBlurs)}
  onEditingRowSave={handleSaveRow}
  data={enableTab ? [] : dataCompraDetalle}
  renderRowActions={({ row,table }) => (
    <Box>
      <IconButton onClick={() => table.setEditingRow(row)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => deleteRow(row)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  )} 
  muiTableBodyCellProps={({ cell }) => {
      return {
        sx: {
          textAlign:cell.column.id==="mrt-row-actions"||cell.column.id==="fcd_precio"||cell.column.id==="fcd_cantidad"||cell.column.id==="fcd_itbms"||cell.column.id==="fcd_total"?"center":"left",  
        }
      };
    }}
/>
}
function DATATABLE_COLUMNS_LISTA_ITEMS({dataItems,selectItem,setTabItems}){
  return <MaterialReactTable
  initialState={{
    pagination: { pageSize: 50, pageIndex: 0 }
  }}
  layoutMode="grid-no-grow" 
  enableEditing={false}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={true} 
  enableTopToolbar={true}
  enableRowActions={false}
  positionActionsColumn="last"
  enablePagination={true}
  enableSorting={true}
  enableFilters={true} 
  muiTableContainerProps={{ sx: { maxHeight: '74vh' } }}
  enableStickyHeader={true}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  enableFullScreenToggle={false}
  enableDensityToggle={false}
  positionGlobalFilter="left"
  muiSearchTextFieldProps={{
    placeholder: "Buscar",
  }}
  enableGlobalFilter={true} 
  muiTableBodyRowProps={({row}) => ({
    sx: {
      backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
    },
    onClick: () => {
      selectItem(row.original.art_upc);
      setTabItems(false)
    }
  })} 

  muiTableBodyRowformFactura={({ row }) => {
  return {
    sx: {
      backgroundColor:
        row.original.re_fecha_procesado !== "0000-00-00 00:00:00"
          ? "rgba(219, 237, 220, 0.78)"
          : "",
      fontWeight: "bold",
    },
  };
}}

columns={COLUMNS_LISTA_ITEMS()}
data={ dataItems}
muiTableContainerformFactura={{
  sx: {
    maxHeight: "290px",
  },
}}
/>
}
function DATATABLE_COLUMNS_LISTA_ANULACION({dataListAnulacion,setTabAnular,setFormAnulacion}){
  return <MaterialReactTable
  initialState={{
    pagination: { pageSize: 50, pageIndex: 0 }
  }}

  layoutMode="grid-no-grow" 
  enableEditing={false}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={true} 
  enableTopToolbar={true}
  enableRowActions={false}
  positionActionsColumn="last"
  enablePagination={true}
  enableSorting={true}
  enableFilters={true} 
  muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
  enableStickyHeader={true}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  enableFullScreenToggle={false}
  enableDensityToggle={false}
  positionGlobalFilter="left"
  muiSearchTextFieldProps={{
    placeholder: "Buscar",
  }}

  enableGlobalFilter={true} 
  muiPaginationProps={{
    showRowsPerPage: false
  }}
  muiTableBodyRowProps={({ row }) => {
    return {
      onClick: () => {
        setTabAnular(false);
        setFormAnulacion({...row.original, motivo_anulacion:""})
      },
      sx: {
        backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
        fontWeight: "bold",
      },
    };
  }}
  columns={COLUMNS_LISTA_ANULACION(setTabAnular,setFormAnulacion)}
  data={ dataListAnulacion}
  muiTableContainerformFactura={{
    sx: {
      maxHeight: "290px",
    },
  }}
/>
}
function DATATABLE_COLUMNS_LISTA_FPAGO({dataListpago,handleSaveRow,deleteRow}){
  return <MaterialReactTable
  layoutMode="grid-no-grow" 
  enableEditing={true}
  enableColumnActions={false}
  editDisplayMode="cell"
  enableBottomToolbar={false} 
  enableTopToolbar={false}
  enableRowActions={true}
  positionActionsColumn="last"
  enablePagination={false}
  enableSorting={false}
  enableFilters={false} 
  columns={COLUMNS_LISTA_FPAGO()}
  onEditingRowSave={handleSaveRow}
  data={ dataListpago}
  renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
  muiTableBodyCellProps={({ cell }) => {
    return {
      sx: {
        textAlign:"center",
      }
    };
  }}
  renderRowActions={({ row,table }) => (
    <Box>
      <IconButton onClick={() => table.setEditingRow(row)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => deleteRow(row)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  )} 
/>
}

function DATATABLE_COLUMNS_LISTA_COTIZACION({selectItemCotizacion,dataListCotizacion}){
  return <MaterialReactTable
  state={{
    selectItemCotizacion
  }}
        muiTableBodyRowProps={({ row }) => {
          return {
            onClick: () => {
              selectItemCotizacion({cot_identificador:row.original.cot_identificador})
            },
            sx: {
              backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
              fontWeight: "bold",
            },
          };
        }}
        muiPaginationProps={{
          showRowsPerPage: false
        }}
        muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
        enableStickyHeader={true}
        positionActionsColumn="last"
        enableGlobalFilter={true} 
        enableFullScreenToggle={false}
        enableColumnActions={false}
        enableEditing={false}
        enableRowActions={false}
        enablePagination={true}
        enableSorting={false}
        enableFilters={true} 
        enableDensityToggle={false}
        positionGlobalFilter="left"
        enableTopToolbar={true}
        columns={COLUMNS_LISTA_COTIZACION()}
        data={ dataListCotizacion}
      
/>
}
function DATATABLE_COLUMNS_RENCUENTO({enableTab,dataRecuentoDetalle,handleSaveRow,deleteRow}){
  return <MaterialReactTable
        muiTableBodyRowProps={({row}) => {
          return ({
          sx: {
            backgroundColor: row.original.dc_fecha_procesado !== "0000-00-00 00:00:00" ? 'rgba(219, 237, 220, 0.78)' : '',
            fontWeight: 'bold',
            }
          })
        }}
        muiPaginationProps={{
          showRowsPerPage: false
        }}
        muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
        enableStickyHeader={true}
        positionActionsColumn="last"
        enableGlobalFilter={false} 
        enableFullScreenToggle={false}
        enableColumnActions={false}
        enableEditing={true}
        enableRowActions={true}
        enablePagination={true}
        enableSorting={false}
        enableFilters={false} 
        enableDensityToggle={false}
        positionGlobalFilter="left"
        enableTopToolbar={false}
        columns={COLUMNS_RE_RECUENTO_DETALLE()}
        data={enableTab?[]:dataRecuentoDetalle}
        onEditingRowSave={handleSaveRow}
        renderRowActions={({ row,table }) => (
          <Box>
            <IconButton onClick={() => table.setEditingRow(row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => deleteRow(row)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )} 
/>
}
  export {DATATABLE_COLUMNS_RENCUENTO,DATATABLE_COLUMNS_LISTA_COTIZACION,DATATABLE_COLUMNS_LISTA_FPAGO,DATATABLE_COLUMNS_LISTA_ANULACION,DATATABLE_DC_COMPRA_DETALLE,DATATABLE_COLUMNS_LISTA_ITEMS,DATATABLE_LISTA_ITEMS2,DATATABLE_INV_INVENTARIO,DATATABLE_DC_COMPRA,DATATABLE_MOVIMIENTOS,DATATABLE_ARTICULO,DATATABLE_APARTADO,DATATABLE_CATEGORIAS,DATATABLE_USUARIOS, DATATABLE_MARCAS,DATATABLE_PROVEEDORES,DATATABLE_CLIENTES,DATATABLE_FACTURA,
    DATATABLE_COTIZACION,DATATABLE_LISTA_CLIENTE,DATATABLE_LISTA_ITEMS,DATATABLE_COTIZACION_DETALLE,DATATABLE_BODEGAS};