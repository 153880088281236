import moment from "moment";
function STATES_DC_COMPRA({staneName}){
if(staneName==="formCompra"){
    return{
        dc_id: "",
        dc_docn: "",
        dc_identificador: moment(new Date()).format("YYYYMMDDHHmmss"),
        dc_proveedor: "",
        prov_proveedor: "",
        dc_procesado: "",
        dcd_total: "",
        dc_fecha_procesado: "",
        dc_fecha: moment(new Date()).format("YYYY-MM-DD"),
        dc_descripcion: "",
        dc_status: "",
      }
}
if(staneName==="formCompraDetalle"){
    return{
        dcd_id: "",
        dcd_articulo: "",
        dcd_articulo: "",
        dcd_precio: "",
        dcd_cantidad: "1",
        dcd_itbms: "",
        dcd_descuento: "",
        dcd_total: "",
        dcd_descuento_number: "",
        art_descripcion: "",
        art_upc:"",
        art_itbm_cod_c:"",
        art_itbm_compra:""
      } 
}
    return null
  }

  function STATES_RE_RECUENTO({staneName}){
    if(staneName==="formRecuento"){
      return{
        re_id: "",
        re_identificador:moment(new Date()).format("YYYYMMDDHHmmss"),
        re_fecha: moment(new Date()).format("YYYY-MM-DD"),
        re_fecha_procesado: "",
        re_procesado: "",
        re_descripcion: "",
        } 
  }
 
  if(staneName==="formRecuentoDetalle"){
    return{
      red_id: "",
      red_articulo: "",
      red_cantidad: "1",
      red_identificador: "",
      art_upc: "",
      art_descripcion: "",
      } 
    }
      return null
  }

  function STATES_MOV_MOVIMIENTO({staneName}){
    if(staneName==="formMovimiento"){
      return{
        art_id: "",
        art_upc:"",
        art_descripcion: "",
        art_categoria: "",
        art_marca: "",
        art_linea_roja: "",
        art_sts:"",
        inv_qty:""
        } 
  }
      return null
  }
  function STATES_FAC_FACTURA({staneName}){
    if(staneName==="formAnulacion"){
      return{
        motivo_anulacion: "",
        docNumber:"",
        fac_id: "",	
        fac_identificador: "",	
        fac_fecha: "",	
        fac_cliente: "",	
        fac_date_anulacion:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        cli_nombre: "",	
        cli_ruc: "",	
        cli_dv: "",	
        fac_total: "",
        motivo_anulacion:""
      }
  }
  if(staneName==="formListPago"){
    return {
      list_id: "",
      list_identificador: "",
      list_fpago: "",
      list_monto: "",
      list_sts: "",
    }
  }
  if(staneName==="dataCompania"){
    return {
      bod_suc: "",
      com_nombre: "",
      com_ruc: "",
      com_dv: "",
      com_direccion: "",
      com_telefono: "",
      com_telefono2: "",
      com_email: "",
      com_fax: "",
      com_web: "",
      com_info_interes:""
    }
  }
  if(staneName==="formClientes"){
    return {
      cli_id: "",
      cli_suc: "",
      cli_sts: "",
      cli_nombre: "",
      cli_direccion: "",
      cli_telefono: "",
      cli_telefono2: "",
      cli_ruc: "",
      cli_dv: "",
      cli_email: "",
    }
  }
  if(staneName==="formDropDownCliente"){
    return {
      cli_id: "",
      cli_nombre: "",
      cli_ruc:"RUC",
      cli_email:"",
      cli_dv:""
    }
  }
  if(staneName==="formDropDownFPago"){
    return {
      frm_id: "",
      frm_code:"",
      frm_descripcion: ""
    }
  }
  if(staneName==="formFacturaDetalle"){
    return {
      fcd_id: "",
      fcd_identificador: "",
      fcd_articulo: "",
      art_descripcion: "",
      art_upc:"",
      fcd_precio: "",
      fcd_cantidad: "0",
      fcd_itbms: "0",
      fcd_art_descripcion:"",
      fcd_itbms_code:"",
      art_itbm_cod_v:"",
      fcd_descuento: "",
      fcd_total: "",
      fcd_descuento_number: "",
      art_itbm_venta:""
    }
  }
  if(staneName==="cotizacionList"){
    return {
      cot_descripcion:"",
      cli_nombre:"", 
      cli_ruc:"",
      cot_fecha:"",
    }
  }
  if(staneName==="formCotizacionDetail"){
    return {
      cotd_articulo:"",
      cotd_cantidad:"",
      cotd_itbms:"",
      cotd_precio:"",
      cotd_total:"",
      art_upc:"",
    }
  }
      return null
  }

  function STATES_COT_COTIZACION({staneName}){
    if(staneName==="formCompania"){
      return{
        bod_suc: "",
        com_nombre: "",
        com_ruc: "",
        com_dv: "",
        com_direccion: "",
        com_telefono: "",
        com_telefono2: "",
        com_email: "",
        com_fax: "",
        com_web: "",
        com_info_interes:"",
        com_cotizacion_info:""
      }
  }
  if(staneName==="formCotCotizacion"){
    return {
      cot_id: "",
      cot_identificador: moment(new Date()).format("YYYYMMDDHHmmss"),
      cot_cliente: "",
      cot_nombre:"",
      cot_fecha: moment(new Date()).format("YYYY-MM-DD"),
      cot_vendedor: "",
      cot_status: "",
      cot_descripcion: ""
    }
}
if(staneName==="formCotCotizacionDetalle"){
  return {
    cotd_id: "",
    cotd_identificador: "",
    cotd_articulo: "",
    cotd_cantidad: "",
    cotd_descuento: "",
    cotd_descuento_number: "",
    cotd_precio: "",
    cotd_itbms: "",
    art_itbm_venta:"",
    cotd_total: "",
    art_descripcion: "",
    art_upc: "",
    art_itbm_cod_v: "",
    art_imgName:""
  }
}
if(staneName==="formClientes"){
  return {
    cli_id: "",
    cli_suc: "",
    cli_sts: "",
    cli_nombre: "",
    cli_direccion: "",
    cli_telefono: "",
    cli_telefono2: "",
    cli_ruc: "",
    cli_dv: "",
    cli_email: "",
  }
}
if(staneName==="formDropDownCliente"){
  return {
    cli_id: "",
    cli_nombre: "",
    cli_ruc: "RUC",
    cli_email: "",
    cli_dv: ""
  }
}
      return null
  }
export {
  STATES_FAC_FACTURA,
    STATES_DC_COMPRA,
    STATES_RE_RECUENTO,
    STATES_MOV_MOVIMIENTO,
    STATES_COT_COTIZACION
  };