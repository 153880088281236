import React, { useState, useEffect, useRef } from "react";
import { Container, Label, Row, Col } from "reactstrap";
import moment from "moment";
import {LineChart} from 'amazing-react-charts'
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { ComponentToPrintsReporteVentas, ComponentToPrintsReporteIC,ComponentToPrintsReporteVM } from "../Inventario/ComponentToPrintss";
import ReactToPrint from "react-to-print-advanced";
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import BarChartIcon from '@mui/icons-material/BarChart';
import { IconButton } from "@mui/material";
import { DateRangeModal } from "../Componentes/Modal";
import { MDBIcon, MDBTabs, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "./../Componentes/www/style.css";
import { get_report_venta, get_report_x_global,get_report_ventas_mensuales } from "./../Componentes/Services";
import{monthname} from "../Componentes/Services";

export const Reporte = () => {
  var subtotal = 0;
  var itbms = 0;
  var total = 0;
  var exento = 0;
  var objeDataTotales = [];
  var componentRef = useRef();
  const [vendedorChecked, setCheckvendedor] = useState(false);
  const [tabsActive, setTabsActive] = useState("tab1");
  const [isPrinting, setPrintI] = useState(false);
  const [isDateRangeOpen, setDateRange] = useState(false);
  const [isPrintingC, setPrintIC] = useState(false);
  const [isPrintingVM, setPrintVM] = useState(false);
  const [dataReporteInformeCaja, setReportInformeCaja] = useState([]);
  const [flagInfomeCaja, setOpenFlagInformeCaja] = useState(false);
  const [flagGraficaVentasMensuales, setFlagVentasMensuales] = useState(false);
  const [dataReporteVentasExento, setReportVentaExento] = useState([]);
  const [dataReporteVentas, setReportVenta] = useState([]);
  const [dataReporteVentasFpago, setReportVentaFpago] = useState([]);
  const [dataReporteVentasMontosT, setReportVentaMontosT] = useState([]);
  const [dataGroupVendedor, setGroupVendedor] = useState([]);
  const [dataReportVentasMensuales, setReportVentasMensuales] = useState([]);
  const [open, setOpen] = useState(false);
  const [flag, setOpenFlag] = useState(0);
  const [state, setState] = useState([{
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    key: "selection"
  }]);

  dataReporteVentasMontosT.map((data) => {
    // if (data.fac_anulacion !== "1") {
      itbms = Number(data.fcd_itbms)
      total =  Number(data.fcd_total)
      subtotal = data.fcd_precio
    // }
  })

if(dataReporteVentasExento[0]!==undefined){
  exento=Number(dataReporteVentasExento[0].fcd_total);
}

  objeDataTotales = {
    itbms,
    subtotal,
    total,
    exento,
    startDate: moment(state[0].startDate).format("YYYY-MM-DD"),
    Endate: moment(state[0].endDate).format("YYYY-MM-DD"),
  }

  const handleTabClick = (value) => {
    if (value === tabsActive) {
      return;
    }
    setTabsActive(value);
    setOpenFlagInformeCaja(false)
    setReportVenta([])
    setReportInformeCaja([])
    setPrintI(false)
    setPrintIC(false)
  };
  
  async function fetchVentasMensuales({ startDate, endDate }) {
    var response = await get_report_ventas_mensuales({startDate, endDate});
    response = response.ventas_mensuales.map((data,i)=>{
      return{
        label: monthname[data.fac_fecha].name,
        result: data.fac_total,
        itbms: data.fac_itbms,
        itemId: 1,
      }
    })
    setReportVentasMensuales(response);

  }

  async function fetchReporte({ startDate, endDate }) {
    var response = await get_report_venta(startDate, endDate);
    setReportVenta(response.ventas_global);
    setReportVentaFpago(response.fPago)
    setReportVentaExento(response.exento)
    setReportVentaMontosT(response.montos_totales)
    var report = [];
    var hashReport = {};
    report = response.ventas_global.filter((o) => (hashReport[o.usr_id] || o.usr_id === null ? false : (hashReport[o.usr_id] = true)));
    setGroupVendedor(report)
  }

  async function fetchReporteInformeCaja({ startDate, endDate }) {
    var response = await get_report_x_global(startDate, endDate);
    setReportInformeCaja(response);
    response.montos_totales[0] !== undefined ? setOpenFlagInformeCaja(true) : setOpenFlagInformeCaja(false);
  }

  const handleOnChangeDate = (ranges) => {
    const { selection } = ranges;
    setOpenFlag(Number(flag) + 1)
    setState([selection]);
    if (flag === 1) {
      if(tabsActive === "tab1"){
        fetchReporte({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD"), group_vendedor: vendedorChecked })
      }
      if(tabsActive === "tab2") {
        fetchReporteInformeCaja({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD") })
      }
      if(tabsActive === "tab3") {
        fetchVentasMensuales({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD") })
      }
      setOpen(false);
      setOpenFlag(0)
      setDateRange(false)
      setOpenFlagInformeCaja(false);
    }
  };

  return (
    <Container fluid>

      {isDateRangeOpen && (
        <DateRangeModal
          isOpen={isDateRangeOpen}
          toggle={setDateRange}
          // setOpen={setOpen}
          open={open}
          handleOnChangeDate={handleOnChangeDate}
          state={state}
        />
      )}
      <Row className="background-divs">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">REPORTES</Label>
        </Col>
      </Row>
      <>
        <MDBTabs className="d-flex-none custom-navs">
          <Row>
            <Col xs={3} >
              <MDBTabsLink onClick={() => handleTabClick("tab1")} active={tabsActive === "tab1"}>
                <MDBIcon fas icon="money-bill" className="me-2" /> Reporte de ventas
              </MDBTabsLink>
            </Col>
            <Col xs={3}>
              <MDBTabsLink onClick={() => handleTabClick("tab2")} active={tabsActive === "tab2"}>
                <MDBIcon fas icon="cash-register" className="me-2" /> Informes de caja
              </MDBTabsLink>
            </Col>
            <Col xs={3}>
              <MDBTabsLink onClick={() => handleTabClick("tab3")} active={tabsActive === "tab3"}>
                <MDBIcon fas icon="cash-register" className="me-2" /> Ventas mensuales
              </MDBTabsLink>
            </Col>
            <Col xs={3}></Col>
          </Row>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane show={tabsActive === "tab1"}>
            {
              isPrinting ?
                <>
                  <Row>
                    <Col xs={10}></Col>
                    <Col xs={2} >
                      <IconButton className="float-right-btn"
                        onClick={() => {
                          setPrintI(false);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <ReactToPrint
                        trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                        content={() => componentRef} />
                    </Col>
                    <Col xs={12} className="text-center scrol-table2">
                      <ComponentToPrintsReporteVentas
                        ref={el => (componentRef = el)}
                        props={dataReporteVentas}
                        dataReporteVentasFpago={dataReporteVentasFpago}
                        objeData={objeDataTotales}
                      // formCompra={formCompra}
                      />
                    </Col>
                  </Row>
                </> :
                <>
                  <Row className="mt-3">
                    <Col xs={12} className="text-center mt-2">
                      <Label className="font-weight-bold-title">REPORTE DE VENTAS</Label>
                    </Col>

                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        label="SUBTOTAL"
                        autoFocus={true}
                        value={dataReporteVentas.length > 0 ? Number(Number(subtotal)).toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        wrapperClass="mb-4"
                        label="ITBMS"
                        value={dataReporteVentas.length > 0 ? itbms.toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        id="fcd_i_total_e"
                        label="EXENTO"
                        value={dataReporteVentas.length > 0 ? exento.toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        id="fcd_i_total"
                        label="TOTAL"
                        value={dataReporteVentas.length > 0 ? total.toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>

                    <Col xs={1} >
                      {dataReporteVentas[0] === undefined ? null :
                        <IconButton
                          onClick={() => {
                            setPrintI(true);
                          }}
                        >
                          <LocalPrintshopIcon />
                        </IconButton>

                      }
                      <IconButton onClick={() => {
                        setOpen(true); setDateRange(true); setState([{
                          startDate: moment(new Date()).format("YYYY-MM-DD"),
                          endDate: moment(new Date()).format("YYYY-MM-DD"),
                          key: "selection"
                        }])
                      }}>

                        <CalendarMonthIcon />
                      </IconButton>

                    </Col>

                    <Col x={3}>
                      {state[0].startDate !== "" && state[0].endDate !== "" && (
                        <p className="date-p-label float-right-btn">Fecha :{" "}
                          {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                          {moment(state[0].endDate).format("YYYY-MM-DD")}
                        </p>
                      )}
                    </Col>
                    <Col xs={12}>
                      {open ? null :
                        <>
                          {dataReporteVentas[0] !== undefined ? <>

                            <Row>
                              <Col xs={12} className="scrol-table">
                                <MDBTable striped className="custom-table">
                                  <MDBTableHead dark className="table-dark-custom">
                                    <tr>
                                      <th className="text-center">N° documento</th>
                                      <th className="text-center">Fecha</th>
                                      <th className="text-center">Cliente</th>
                                      <th className="text-center">Vendedor</th>
                                      <th className="text-center">Caja</th>
                                      <th className="text-center">Fecha Anulado</th>
                                      <th className="text-center">Anulacion</th>
                                       <th className="text-center w-500px">EXENTO</th>
                                      <th className="text-center">subtotal</th>
                                      <th className="text-center">ITBMS</th>
                                      <th className="text-center">F. Pago</th>
                                      <th className="text-centerx">Total</th>
                                    </tr>
                                  </MDBTableHead>
                                  <MDBTableBody>
                                    {
                                      dataReporteVentas.map((data, i) => {
                                        return <tr key={i}>
                                          <td className="text-center">
                                            <Label>{data.fac_id.toString().padStart(10, "0")}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_fecha}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.cli_nombre}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.usr_nombre}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_caja}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_date_anulacion}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_anulacion !== "1" ? "" : "ANULADO"}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fcd_itbms_code==="00"?"Exento":null}</Label>
                                          </td>

                                          <td className="text-center">
                                            <Label>{data.fcd_precio}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{Number(data.fcd_itbms).toFixed(2)}</Label>
                                          </td>
                                          <td className="text-center">
                                            {
                                              dataReporteVentasFpago.map((datap, i) => {
                                                if (data.fac_id === datap.fac_id) {
                                                  return <><Label className="label-fpago">&nbsp;&nbsp;&nbsp;{datap.fcfp_fpago}{": "}{datap.fcfp_monto}</Label></>
                                                }
                                              })
                                            }
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fcd_total}</Label>
                                          </td>
                                        </tr>
                                      })
                                    }
                                  </MDBTableBody>
                                </MDBTable>

                              </Col>
                            </Row>
                          </>
                            : null}
                        </>
                      }
                    </Col>
                  </Row>
                </>
            }
          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab2"}>
            {isPrintingC ?
              <>
                <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintIC(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                    <ComponentToPrintsReporteIC
                      ref={el => (componentRef = el)}
                      dataReporteInformeCaja={dataReporteInformeCaja}
                    />
                  </Col>
                </Row>
              </> :
              <>
                <Row>
                  <Col xs={12} className="text-center mt-3">
                    <Label className="font-weight-bold-title">REPORTE DE INFORMES DE CAJA</Label>
                  </Col>
                  <Col xs={9} className="text-end">
                    {flagInfomeCaja === false ? null : <IconButton
                      onClick={() => {
                        setPrintIC(true);
                      }}
                    >
                      <LocalPrintshopIcon />
                    </IconButton>
                    }
                    <IconButton onClick={() => {
                      setOpen(true); setDateRange(true); setState([{
                        startDate: moment(new Date()).format("YYYY-MM-DD"),
                        endDate: moment(new Date()).format("YYYY-MM-DD"),
                        key: "selection"
                      }])
                    }}>
                      <CalendarMonthIcon />
                    </IconButton>

                  </Col>
                  <Col xs={3} className="text-right">
                    {state[0].startDate !== "" && state[0].endDate !== "" && (
                      <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(state[0].endDate).format("YYYY-MM-DD")}
                      </p>
                    )}
                  </Col>
                  <Col>
                    {flagInfomeCaja ?
                      <>
                        <Row className="scrol-table3">
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Ventas registradas</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Forma de pago</th>
                                  <th>Monto</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.montos_fpago.map((data, i) => {
                                    return <tr>
                                      <th>{data.fcfp_fpago}</th>
                                      <td>{data.fcfp_monto}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>

                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Total de exentos de ITBMS</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Total</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.exentos.map((data, i) => {
                                    return <tr>
                                      <td>{data.fcd_total}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Anulaciones registradas</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Vendedor</th>
                                  <th>Numero de documento</th>
                                  <th>Emitido</th>
                                  <th>Anulado</th>
                                  <th>Total</th>
                                  <th>Motivo</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.anulacion.map((data, i) => {
                                    return <tr>
                                      <th>{data.usr_nombre}</th>
                                      <td>{data.fac_id}</td>
                                      <th>{data.fac_fecha}</th>
                                      <td>{data.fac_date_anulacion}</td>
                                      <th>{data.fcd_total}</th>
                                      <td>{data.fac_motivo_anulacion}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Totales</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Ventas subtotal</th>
                                  <th>Ventas con exento</th>
                                  <th>ITBMS</th>
                                  <th>Total</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.montos_totales.map((data, i) => {
                                    return <tr>
                                      <th>{Number(data.fcd_precio)+Number(dataReporteInformeCaja.exentos[0].fcd_total)}</th>
                                      <th>{Number(dataReporteInformeCaja.exentos[0].fcd_total).toFixed(2)}</th>
                                      <td>{Number(data.fcd_itbms).toFixed(2)}</td>
                                      <th>{data.fcd_total}</th>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                        </Row>

                      </> : null
                    }
                  </Col>
                </Row>
              </>
            }
          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab3"}>
            {isPrintingVM ?
              <>
                <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintVM(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                    <ComponentToPrintsReporteVM
                      ref={el => (componentRef = el)}
                      dataReportVentasMensuales={dataReportVentasMensuales}
                      endDate ={state[0].endDate}
                      startDate={state[0].startDate}
                    />
                  </Col>
                </Row>
              </>:          <Row>
            <Col xs={12} className="text-center mt-3">
              <Label className="font-weight-bold-title">REPORTE VENTAS MENSUALES</Label>
            </Col>
            <Col xs={9} className="text-end">
              <IconButton onClick={() => {
                setOpen(true); setDateRange(true); setFlagVentasMensuales(false);setState([{
                  startDate: moment(new Date()).format("YYYY-MM-DD"),
                  endDate: moment(new Date()).format("YYYY-MM-DD"),
                  key: "selection"
                }])
              }}>
                <CalendarMonthIcon />
              </IconButton>
              {flagGraficaVentasMensuales?<IconButton onClick={() => {setFlagVentasMensuales(false)}}>
                <ClearIcon />
              </IconButton>:<>
                <IconButton onClick={() => {setFlagVentasMensuales(true)}}>
                  <BarChartIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setPrintVM(true);
                  }}>
                  <LocalPrintshopIcon />
                </IconButton>
              </>
              }
            </Col>
            <Col xs={3} className="text-right">
                    {state[0].startDate !== "" && state[0].endDate !== "" && (
                      <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(state[0].endDate).format("YYYY-MM-DD")}
                      </p>
                    )}
                  </Col>
            <Col xs={12} className="text-center">
            {flagGraficaVentasMensuales?
              <LineChart
              showBarLabel
              yComplement="$"
              xType="category"
              yType="value"
              colors={['red', 'green', 'blue']}
              title="REPORTE VENTAS MENSUALES"
              toolboxTooltip={{ saveAsImage: 'save' }}
              // tooltip={{ label: 'Equipamento', result: 'Disponibilidade' }}
              data={[{name:"Venta",values:dataReportVentasMensuales}]}
            />:
            dataReportVentasMensuales[0] !==undefined? <MDBTable striped className="custom-table">
              <MDBTableHead dark>
                <tr>
                  <th>Mes</th>
                  <th>ITBMS</th>
                  <th>Monto</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {
                  dataReportVentasMensuales.map((data, i) => {
                    return <tr>
                      <th>{data.label}</th>
                      <th>{data.itbms}</th>
                      <td>{data.result}</td>
                    </tr>
                  })
                }
              </MDBTableBody>
            </MDBTable>:null
            }

            </Col>
          </Row>}

          </MDBTabsPane>
        </MDBTabsContent>
      </>
      <Row className="background-divs fixed-div">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">.</Label>
        </Col>

      </Row>
    </Container>
  )
}