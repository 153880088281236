import React from 'react'
import {Container,Row, Col,Label } from "reactstrap";
import { CASH_BOX_IMAGE,LOGO } from '../Componentes/Image'
export const Home = () => {
  return (
    <Container >
      <Row>
        <Col xs={12} className="text-center mt-5">
          <img src={LOGO} className="cash-box-img" alt="..." />
        </Col>
        <Col xs={12} className="text-center mt-4 mb-4">
          <Label>
            MTC SOFTWARE PUNTO DE VENTA A LA MEDIDA
          </Label>
        </Col>
      </Row>
    </Container>
  )
}
