import React, { useState, useEffect } from "react";
import { validate } from 'react-email-validator';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../Componentes/www/style.css";
import {DATATABLE_CLIENTES} from "../../Componentes/Datatables";
import {FIND_DESC} from "./../../Componentes/Util";
import {
  ClienteModal,
  DeleteModal
} from "./../../Componentes/Modal";

import {
  get_cli_cliente,
  post_cli_cliente,
  put_cli_cliente,
  put_cli_cliente_sts,
} from "../../Componentes/Services";
import { Container, Row, Col,Label} from "reactstrap";
export const Clientes = () => {
    const [basicModal, setBasicModal] = useState(false);
    const [basicModalEdit, setBasicModalEdit] = useState(false);
    const [isLoadingC, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [basicModalDelete, setBasicModalDelete] = useState(false);
    const [dataClientes, setDataClientes] = useState([]);
    const [formClientes, setFormClientes] = useState({
      cli_id: "",
      cli_suc: "",
      cli_sts: "",
      cli_nombre: "",
      cli_direccion: "",
      cli_telefono: "",
      cli_telefono2: "",
      cli_ruc: "",
      cli_dv: "",
      cli_email: "",
    });
    
    const setFormClientesBlank = () => {
      setFormClientes({
        cli_id: "",
        cli_suc: "",
        cli_sts: "",
        cli_nombre: "",
        cli_direccion: "",
        cli_telefono: "",
        cli_telefono2: "",
        cli_ruc: "",
        cli_dv: "",
        cli_email: "",
        })
        }
    const onClickUpdateClient = () => {
      var body = {
        cli_id: formClientes.cli_id,
        cli_nombre: formClientes.cli_nombre,
        cli_direccion: formClientes.cli_direccion,
        cli_telefono: formClientes.cli_telefono,
        cli_telefono2: formClientes.cli_telefono2,
        cli_ruc: formClientes.cli_ruc,
        cli_dv: formClientes.cli_dv,
        cli_email: formClientes.cli_email,
      };
      if (formClientes.cli_nombre.length > 0) {
        if(formClientes.cli_email.length>0){
          if(validate(formClientes.cli_email)){
            if(FIND_DESC(dataClientes,formClientes.cli_ruc,formClientes.cli_id)){
              setError("Error: Ruc ya esta registrado");
            }
            else{
              put_cli_cliente(body);
              setTimeout(() => {
                fetchCliente();
              }, 1000);
            }
          }
          else{
            setError("E-Mail incorrecto");
          }
        }
        else{
          if(FIND_DESC(dataClientes,formClientes.cli_ruc,formClientes.cli_id)){
            setError("Error: Ruc ya esta registrado");
          }
          else{
            put_cli_cliente(body);
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
  
      } else {
        setError("Campos importantes estan vacios");
      }
    };
  
    const onClickUpdateCliente_sts = () => {
      var body = {
        cli_id: formClientes.cli_id,
        cli_sts: 0,
      };
  
      put_cli_cliente_sts(body);
      setTimeout(() => {
        fetchCliente();
      }, 1000);
    };
  
    const onClickSaveCliente = () => {
      var body = {
        cli_nombre: formClientes.cli_nombre,
        cli_direccion: formClientes.cli_direccion,
        cli_telefono: formClientes.cli_telefono,
        cli_telefono2: formClientes.cli_telefono2,
        cli_ruc: formClientes.cli_ruc,
        cli_dv: formClientes.cli_dv,
        cli_email: formClientes.cli_email,
      };
      if (formClientes.cli_nombre.length > 0) {
        if(formClientes.cli_email.length>0){
          if(validate(formClientes.cli_email)){
            if(FIND_DESC(dataClientes,formClientes.cli_ruc,null)){
              setError("Error: Ruc ya esta registrado");
            }
            else{
              post_cli_cliente(body);
              setTimeout(() => {
                fetchCliente();
              }, 1000);
            }
          }
          else{
            setError("E-Mail incorrecto");
          }
        }
        else{
          if(FIND_DESC(dataClientes,formClientes.cli_ruc,null)){
            setError("Error: Ruc ya esta registrado");
          }
          else{
            post_cli_cliente(body);
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
  
      } else {
        setError("Campos importantes estan vacios");
      }
    };
  
    async function fetchCliente() {
      setLoading(true);
      var response;
      response = await get_cli_cliente();
      setDataClientes(response);
      setBasicModal(false);
      setBasicModalDelete(false);
      setBasicModalEdit(false)
      setLoading(false);
      setError("");
    }
  
    function onChange(event) {
      const { value, name } = event.target;
      setFormClientes({
        ...formClientes,
        [name]: value,
      });
      setError("");
    }
  
    useEffect(() => {
      fetchCliente();
    }, []);
  
    return (
      <Container>
        {basicModal && (
          <ClienteModal
            props={formClientes}
            isOpen={basicModal}
            basicModalEdit={basicModalEdit}
            toggle={setBasicModal}
            toggleSave={onClickSaveCliente}
            toggleSaveEdit={onClickUpdateClient}
            onChange={onChange}
            error={error}
            setFormClientesBlank={setFormClientesBlank}
          />
        )}
        {basicModalDelete && (
          <DeleteModal
            props={formClientes.cli_nombre+" "+ formClientes.cli_ruc+" "+formClientes.cli_dv}
            isOpen={basicModalDelete}
            toggle={setBasicModalDelete}
            toggleDelete={onClickUpdateCliente_sts}
            text={"Eliminar este registro?"}
          />
        )}
        <Row>
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">CLIENTES</Label>
        </Col>
          <Col className="modal-add-inventario datatable-factura">
            <DATATABLE_CLIENTES dataClientes={dataClientes} 
              setBasicModalDelete={setBasicModalDelete} 
              setFormClientes={setFormClientes} setBasicModal={setBasicModal}
             setBasicModalEdit={setBasicModalEdit} setError={setError} fetchBodega={fetchCliente} isLoadingC ={isLoadingC}/>
          </Col>
        </Row>
      </Container>
    );

}
