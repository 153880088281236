import React, { useContext } from "react";
import {  Navigate,Outlet  } from "react-router-dom";
import StoreContext from "./Store/Context";
import { getKeyToke } from "./Util";
import {reactLocalStorage} from 'reactjs-localstorage';

const RoutesPrivate = ({ ...rest },path) => {
  const { token, usr_type,usr_id,usr_nombre,usr_ajustes,usr_factura,usr_inventario,usr_cotizacion,usr_articulo,usr_reporte,usr_apartado,usr_clientes} = useContext(StoreContext);
  const tokenKey = getKeyToke({usr_type,usr_id,usr_nombre})
  var x = reactLocalStorage.getObject('tokenP');
  var flag= false;
  if(window.location.pathname.includes("factura") &&  usr_factura==="1")
    flag = true
  if(window.location.pathname.includes("cotizacion") &&  usr_cotizacion==="1")
    flag = true
  if(window.location.pathname.includes("articulos") &&  usr_articulo==="1")
    flag = true
  if(window.location.pathname.includes("reporte") &&  usr_reporte==="1")
    flag = true
  if(window.location.pathname.includes("inventario") &&  usr_inventario==="1")
    flag = true
  if(window.location.pathname.includes("configuracion") &&  usr_ajustes==="1")
    flag = true
  if(window.location.pathname.includes("clientes") &&  usr_clientes==="1")
    flag = true
  if(window.location.pathname.includes("apartado") &&  usr_apartado==="1")
    flag = true
  if(tokenKey===x.key && flag)
  {
    return token?<Outlet/>:<Navigate to ="/"/>
  }
  else{
    return <Navigate to ="/"/>
  }
};

export default  RoutesPrivate ;
