import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print-advanced";
import { Container, Label, Row, Col,Button } from "reactstrap";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {DATATABLE_APARTADO} from "../Datatables";
import { COLUMNS_AP_APARTADO } from "../DatatableColumns";
import {MaterialReactTable,MRT_FullScreenToggleButton,MRT_ToggleDensePaddingButton,} from "material-react-table";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CachedIcon from "@mui/icons-material/Cached";
import EditIcon from "@mui/icons-material/Edit";
import { ApartadoModal } from "../Modal";
import { Box, IconButton } from "@mui/material";
import {get_cli_cliente,
  get_apartado,
  put_ap_apartado_sts,
  get_articulo_by_code,
  post_ap_apartado,
  get_apartado_detalle,
  put_ap_apartado,
  delete_ap_apartado_detalle,
  put_apd_apartado_detalle_row,
  post_ap_apartado_detalle_row,
  put_apd_apartado_encabezado,
  processInventario,
} from "../Services";
import { ComponentToPrintss } from "../../Inventario/ComponentToPrintss";

export const Apartado = () => {
  var componentRef = useRef();
  const [isPrinting, setPrintI] = useState(false);
  const [isLoadingI, setLoading] = useState(false);
  const [isSaveI, setSaveI] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [error, setError] = useState("");
  const [dataCliente, setDataCliente] = useState([]);
  const [error_upc, setError_upc] = useState("");
  const [dataapartado, setDataapartado] = useState([]);
  const [dataapartadoDetalle, setOBJapartadoDetalle] = useState([]);
  const [formClientes, setFormClientes] = useState({
    cli_id: "",
    cli_suc: "",
    cli_sts: "",
    cli_nombre: "",
    cli_direccion: "",
    cli_telefono: "",
    cli_telefono2: "",
    cli_ruc: "",
    cli_dv: "",
    cli_email: "",
  });
  const [formapartado, setFormapartado] = useState({
    ap_id: "",
    ap_docn: "",
    ap_identificador: "",
    ap_proveedor: "",
    prov_proveedor: "",
    ap_procesado: "",
    apd_total: "",
    ap_fecha_procesado: "",
    ap_fecha: moment(new Date()).format("YYYY-MM-DD"),
    ap_descripcion: "",
    ap_status: "",
  });
  const [formapartadoDetalle, setFormapartadoDetalle] = useState({
    apd_id: "",
    apd_articulo: "",
    apd_articulo: "",
    apd_precio: "",
    apd_cantidad: "1",
    apd_itbms: "",
    apd_descuento: "",
    apd_total: "",
    apd_descuento_number: "",
    art_descripcion: "",
    art_upc:"",
    art_itbm_cod_c:"",
    art_itbm_apartado:""
  });
  const onClickUpdateapartado_sts = ({ ap_id, ap_status }) => {
    var body = {
      ap_id: ap_id,
      ap_status: ap_status,
    };
    put_ap_apartado_sts(body);
    setTimeout(() => {
      fetchDCapartado();
    }, 1000);
  };

  async function fetchDCapartadoDetalle(ap_identificador) {
    setLoading(true);
    var response;
    response = await get_apartado_detalle(ap_identificador);
    setOBJapartadoDetalle(response);
    setLoading(false);
  }

  async function fetchDCapartado() {
    setLoading(true);
    var response;
    response = await get_apartado();
    setDataapartado(response);
    setBasicModal(false);
    setLoading(false);
    setError("");
    setError_upc("");
  }
  async function fetchCliente() {
    var response;
    response = await get_cli_cliente();
    setDataCliente(response);
    // setBasicModalEditCliente(false);
    // setModalAddCliente(false);
    // setFormClientesBlank()
  }
  
  async function fetchArticuloByUpc(art_upc) {
    var response;
    response = await get_articulo_by_code(art_upc);
    if (response[0] !== undefined) {
      setError_upc("");
      setFormapartadoDetalle({
        ...formapartadoDetalle,
        apd_articulo: response[0].art_id,
        apd_precio: response[0].art_costo,
        art_descripcion: response[0].art_descripcion,
        apd_itbms: response[0].art_itbm_apartado,
        art_upc:response[0].art_upc,
        art_itbm_cod_c:response[0].art_itbm_cod_c,
        art_itbm_apartado:response[0].art_itbm_apartado,
      });
    } else {
      setError_upc("Articulo no existe");
    }
  }

  useEffect(() => {
    fetchDCapartado();
    fetchCliente();
  }, []);

  const onClickSaveapartado = () => {
    if (
      formapartado.ap_docn.length > 0 &&
      dataapartadoDetalle[0] !== undefined
    ) {
      var dataap_detail = dataapartadoDetalle.map((val, i) => {
        return {
          apd_identificador: formapartado.ap_identificador,
          apd_articulo: val.apd_articulo,
          apd_precio: val.apd_precio,
          apd_cantidad: val.apd_cantidad,
          apd_articulo_desc:val.art_descripcion,	
          apd_itbms: val.apd_itbms,
          apd_descuento: val.apd_descuento,
          apd_total:val.apd_total,
          apd_descuento_number: val.apd_descuento,
        };
      });
      var body = {
        formapartado: {
          ap_docn: formapartado.ap_docn,
          ap_identificador: formapartado.ap_identificador,
          ap_fecha: formapartado.ap_fecha,
          ap_descripcion: formapartado.ap_descripcion,
        },
        dataapartadoDetalle: dataap_detail,
      };
  
      post_ap_apartado(body);
      setSaveI(true);
      setTimeout(() => {
        setSaveI(false);
        fetchDCapartado();
      }, 2000);
    } else {
      setError("Campos vacios");
    }
  };

  function onChange(event) {
    const { value, name } = event.target;
    setFormapartado({
      ...formapartado,
      [name]: value,
    });
    setError("");
  }

  function onChangeap_detail(event) {
    const { value, name } = event.target;
    setFormapartadoDetalle({
      ...formapartadoDetalle,
      [name]: value,
      // art_descripcion: "",
    });

    if (event.key === "Enter" && name === "art_upc") {
      fetchArticuloByUpc(value);
    }

    setError("");
    setError_upc("");
  }

  function handleKeyPress(event) {

    const { value, name } = event.target;
    if (event.key === "Enter" && name === "art_upc") {
      fetchArticuloByUpc(value);
    }

    if (event.key === "Enter" && name === "apd_cantidad") {
      if(basicModalEdit){
        var flag = false;
        var itbms=0;
        var cantidad =0;
        var total =0;
        var objItems = dataapartadoDetalle.filter((data, i) => {
          if (data.apd_articulo === formapartadoDetalle.apd_articulo && data.art_descripcion === formapartadoDetalle.art_descripcion) {
            flag = true;
            // data.apd_cantidad = Number(data.apd_cantidad) + Number(formapartadoDetalle.apd_cantidad);

            cantidad = (Number(data.apd_cantidad) + Number(formapartadoDetalle.apd_cantidad)).toFixed(2);
            itbms = ((Number(cantidad)*Number(data.apd_precio))*Number(data.art_itbm_apartado)).toFixed(2);
            data.apd_cantidad = Number(cantidad).toFixed(2);
            data.apd_itbms = itbms;
            data.apd_total = ((Number(cantidad)*Number(formapartadoDetalle.apd_precio))+(Number(itbms))).toFixed(2);

            return data;
          }});
        if (!flag) {
          itbms = ((Number(formapartadoDetalle.apd_cantidad)*Number(formapartadoDetalle.apd_precio))*Number(formapartadoDetalle.art_itbm_apartado)).toFixed(2);
          total = (Number(formapartadoDetalle.apd_cantidad)*Number(formapartadoDetalle.apd_precio))+Number(itbms);
          var bodyInsertRow={
            apd_identificador:formapartado.ap_identificador,
            apd_articulo:formapartadoDetalle.apd_articulo,	
            apd_precio:formapartadoDetalle.apd_precio,	
            apd_articulo_desc:formapartadoDetalle.art_descripcion,	
            apd_cantidad:	formapartadoDetalle.apd_cantidad,
            apd_itbms:itbms,	
            apd_total:total.toFixed(2),	
          }
         
          post_ap_apartado_detalle_row(bodyInsertRow)
        } else {
          var bodyInsertRow={
            apd_id:objItems[0].apd_id,
            apd_identificador:formapartado.ap_identificador,
            apd_articulo:formapartadoDetalle.apd_articulo,	
            apd_articulo_desc:formapartadoDetalle.art_descripcion,	
            apd_precio:formapartadoDetalle.apd_precio,	
            apd_cantidad: Number(objItems[0].apd_cantidad),
            apd_itbms:Number(objItems[0].apd_itbms),	
            apd_total:Number(objItems[0].apd_total),	
          }
          put_apd_apartado_detalle_row(bodyInsertRow)
        }
        setTimeout(() => {
          fetchDCapartadoDetalle(formapartado.ap_identificador)
        }, 2000);
      }
      else{
        var flag = false;
        var itbms=0;
        var cantidad =0;
        var objItems = dataapartadoDetalle.map((data, i) => {
          if (data.apd_articulo === formapartadoDetalle.apd_articulo &&
            data.art_descripcion === formapartadoDetalle.art_descripcion) {
            cantidad = (Number(data.apd_cantidad) + Number(formapartadoDetalle.apd_cantidad)).toFixed(2);
            itbms = ((Number(cantidad)*Number(data.apd_precio))*Number(data.art_itbm_apartado)).toFixed(2);
            flag = true;
            data.apd_cantidad = Number(cantidad).toFixed(2);
            data.apd_itbms = itbms;
            data.apd_total = ((Number(cantidad)*Number(formapartadoDetalle.apd_precio))+(Number(itbms))).toFixed(2);
          }
          return data;
        });
        if (!flag) {
          itbms = ((Number(formapartadoDetalle.apd_cantidad)*Number(formapartadoDetalle.apd_precio))*Number(formapartadoDetalle.art_itbm_apartado)).toFixed(2);
          setOBJapartadoDetalle([...dataapartadoDetalle, ...[{...formapartadoDetalle,
            apd_itbms:itbms,
            apd_total:((Number(formapartadoDetalle.apd_cantidad)*Number(formapartadoDetalle.apd_precio))+(Number(itbms))).toFixed(2),
          }]]);
  
        } else {
          setOBJapartadoDetalle(objItems);
        }
        // if (!flag) {
        //   setOBJapartadoDetalle([...dataapartadoDetalle, ...[formapartadoDetalle]]);
        // } else {
        //   setOBJapartadoDetalle(objItems);
        // }
      }
      setFormapartadoDetalle({
        apd_id: "",
        apd_identificador: "",
        apd_articulo: "",
        apd_precio: "",
        apd_cantidad: "1",
        apd_itbms: "",
        apd_descuento: "",
        apd_total: "",
        apd_descuento_number: "",
        art_descripcion: "",
        art_upc:""
      });
    }
    setError("");
  }

  const deleteRow = (row) => {
    if(basicModalEdit){
      delete_ap_apartado_detalle(row.original)
      setTimeout(() => {
        fetchDCapartadoDetalle(row.original.apd_identificador)
      }, 1000);
    }
    else{
      var value = dataapartadoDetalle.filter((data, i) => {
        if (i !== row.index) return data;
      });
      setOBJapartadoDetalle(value);
    }
  };

  const update_apd_apartado_detalle_row = (values,apd_identificador) => {
    put_apd_apartado_detalle_row(values)
    setTimeout(() => {
      fetchDCapartadoDetalle(apd_identificador)
    }, 1000);
  };

  const toggleSaveEncabezado = () => {
    var body={
      ap_id:formapartado.ap_id,
      ap_docn:formapartado.ap_docn,
      ap_descripcion:formapartado.ap_descripcion
    }
    put_apd_apartado_encabezado(body)
    setTimeout(() => {
      get_apartado()
    }, 1000);
  };

  const onBlurF = (event,row) => {
      var data = dataapartadoDetalle.filter((datos,i)=>{
        if(i !== row.index){
          return datos
        }
      })
      setOBJapartadoDetalle(dataapartadoDetalle => [...data, ...
        [{
          art_descripcion: row.original.art_descripcion,
          apd_cantidad: event.target.value,
          apd_itbms: row.original.apd_itbms,
          apd_precio: row.original.apd_precio,
          apd_total: row.original.apd_total,
          apd_articulo: row.original.apd_articulo,
        }]
      ])
  };

  const handleSelectCliente = ( row) => {
    setFormClientes(row);
    // setModalCliente(false);
  };
  return (
    <Container className="bg-light border" fluid>
      {basicModal && (
        <ApartadoModal
          props={formapartado}
          dataapartadoDetalle={dataapartadoDetalle}
          propsDCDetail={formapartadoDetalle}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveapartado}
          onChange={onChange}
          onChangeap_detail={onChangeap_detail}
          handleKeyPress={handleKeyPress}
          onBlurs={onBlurF}
          error={error}
          error_upc={error_upc}
          isSaveI={isSaveI}
          deleteRow={deleteRow}
          setOBJapartadoDetalle={setOBJapartadoDetalle}
          fetchDCapartado={fetchDCapartado}
          update_apd_apartado_detalle_row={update_apd_apartado_detalle_row}
          toggleSaveEncabezado={toggleSaveEncabezado}
          dataClientes={dataCliente}
          selectCliente={handleSelectCliente}
          formClientes={formClientes}
        />
      )}
      <Row className="mb-4 text-center">
        <Col xs={12}>
          <Label className="font-weight-bold">Apartado</Label>
        </Col>
      </Row>
      <Row>
        {isPrinting ? (
          <React.Fragment>
            <Col xs={10} className="text-end">
            </Col>
            <Col xs={2}>
                    <IconButton
                      onClick={() => {
                        setPrintI(false);
                        setFormapartado({
                          ap_id: "",
                          ap_docn: "",
                          ap_identificador: "",
                          ap_proveedor: "",
                          prov_proveedor: "",
                          ap_procesado: "",
                          apd_total: "",
                          ap_fecha_procesado: "",
                          ap_fecha: moment(new Date()).format("YYYY-MM-DD"),
                          ap_descripcion: "",
                          ap_status: "",
                        });
                        setOBJapartadoDetalle([]);
                      }}
                    >
                       <ArrowBackIcon />
                      Cerrar
                     
                    </IconButton>
                    

              <ReactToPrint
              trigger={() => <a href="#">  <IconButton><LocalPrintshopIcon/></IconButton>Imprimir</a>}
              content={() => componentRef}/>
            </Col>
            <Col xs={12} className="text-center">
              <ComponentToPrintss
                ref={el => (componentRef = el)}
                props={dataapartadoDetalle}
                formapartado={formapartado}
              />
            </Col>
          </React.Fragment>
        ) : (
          <Col className="modal-add-inventario datatable-factura">
            <DATATABLE_APARTADO dataapartado ={dataapartado} onClickUpdateapartado_sts  ={onClickUpdateapartado_sts} setFormapartado ={setFormapartado}setBasicModal ={setBasicModal}setBasicModalEdit ={setBasicModalEdit}
            setOBJapartadoDetalle ={setOBJapartadoDetalle} fetchDCapartadoDetalle ={fetchDCapartadoDetalle}
            setFormapartadoDetalle={setFormapartadoDetalle} fetchDCapartado={fetchDCapartado} setPrintI={setPrintI}/>
          </Col>
        )}
      </Row>
    </Container>
  );
};