import React, {Component } from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';
import StoreContext from './Context';
import {validateTokenStorage,getKeyToke } from "./../Util";

var x = reactLocalStorage.getObject('tokenP');

if(validateTokenStorage()){
  reactLocalStorage.clear();
}

class StoreProvider extends Component{
  constructor(props) {
    super(props);
    this.state = {
      token:x.token,
      usr_perfil:x.usr_perfil,
      usr_id:x.usr_id,
      usr_nombre:x.usr_nombre,
      usr_sucursal:x.usr_sucursal,
      usr_print:x.usr_print,
      usr_ajustes:x.usr_ajustes,
      usr_factura:x.usr_factura,
      usr_inventario:x.usr_inventario,
      usr_cotizacion:x.usr_cotizacion,
      usr_precio:x.usr_precio,
      usr_articulo:x.usr_articulo,
      usr_reporte:x.usr_reporte,
      usr_apartado:x.usr_apartado,
      usr_clientes:x.usr_clientes,
      children: props.children,
      key:x.key
    };

    this.setToken = this.setToken.bind(this);
    this.quitToken = this.quitToken.bind(this);
  }

  setToken = ({token, usr_perfil, usr_id,usr_nombre,usr_sucursal,usr_print, usr_ajustes,
    usr_factura,
    usr_inventario,
    usr_cotizacion,
    usr_articulo,
    usr_precio,
    usr_reporte,usr_apartado,usr_clientes})=> {
    const props = this.state;
    const keyToken = getKeyToke({usr_perfil:usr_perfil,usr_id:usr_id,usr_nombre:usr_nombre,usr_sucursal:usr_sucursal,usr_print:usr_print,
      usr_ajustes:usr_ajustes,
      usr_factura:usr_factura,
      usr_inventario:usr_inventario,
      usr_cotizacion:usr_cotizacion,
      usr_articulo:usr_articulo,
      usr_apartado:usr_apartado,
      usr_clientes:usr_clientes,
      usr_precio:usr_precio,
      usr_reporte:usr_reporte})
    this.setState({
      token,
      usr_perfil,
      usr_id,
      usr_nombre,
      usr_sucursal,
      usr_print,
      usr_ajustes,
      usr_factura,
      usr_inventario,
      usr_cotizacion,
      usr_articulo,
      usr_reporte,
      usr_apartado,
      usr_clientes,
      usr_precio,
      key:keyToken
    });
    reactLocalStorage.setObject('tokenP',{token,usr_perfil,usr_id,usr_nombre,usr_sucursal,usr_print,usr_ajustes,
      usr_factura,
      usr_inventario,
      usr_cotizacion,
      usr_articulo,
      usr_apartado,
      usr_clientes,
      usr_precio,
      usr_reporte,key:keyToken});
  }

  quitToken = ()=> {
 
    this.setState({
      token:"",
      usr_perfil:"",
      usr_id:"",
      usr_nombre:"",
      usr_sucursal:"",
      usr_print:"",
      usr_ajustes:"",
      usr_factura:"",
      usr_inventario:"",
      usr_cotizacion:"",
      usr_articulo:"",
      usr_reporte:"",
      usr_apartado:"",
      usr_clientes:"",
      usr_precio:"",
      key:""
    });
    reactLocalStorage.clear();

  }
  
  render() {
    const props= this.state;
    return (
      <StoreContext.Provider value={{token:props.token,usr_perfil:props.usr_perfil,usr_id:props.usr_id,
        usr_nombre:props.usr_nombre,usr_sucursal:props.usr_sucursal,usr_print:props.usr_print, usr_ajustes:props.usr_ajustes,
        usr_factura:props.usr_factura,
        usr_inventario:props.usr_inventario,
        usr_cotizacion:props.usr_cotizacion,
        usr_articulo:props.usr_articulo,
        usr_reporte:props.usr_reporte,key:props.key,
        usr_apartado:props.usr_apartado,
        usr_clientes:props.usr_clientes,
        usr_precio:props.usr_precio,
      setToken:this.setToken,quitToken:this.quitToken}}>
            {props.children}
      </StoreContext.Provider>
    );
  }
}

export default StoreProvider;