import React, { useContext } from "react";
import StoreContext from "./Store/Context";
import { NavLink as RRNavLink, Outlet } from "react-router-dom";
import { NavItem, Label, Nav, NavLink, Col, Row, NavbarText, Container } from "reactstrap";
import { APARTS_IMAGE,CASH_BOX_IMAGE,LOGO, SETTING_IMAGE, INVENTARIO_IMAGE, ARTICULOS_IMAGE, CASH_REGISTER_IMAGE, COTIZACION_IMAGE, EXIT_IMAGE, REPORTE_IMAGE,CLIENTS_IMAGE} from "./Image";

const Layout = ({ children }) => {
  const { token, usr_ajustes, usr_factura, usr_inventario, usr_cotizacion, usr_articulo, usr_reporte,usr_apartado,usr_clientes, quitToken } = useContext(StoreContext);

  if (!token) {
    document.location.href = "/";
  }
  if(token ===""){
    return null
  }
  else{
    return (
      <React.Fragment>
        <Container>
          <Row className="menu-color-nav bg-light border mt-2 layout-bg-border">
            <Col sm={12}>
              <Nav tabs >
                <Row className="mb-5">
                  <Col xs={12} className="text-center">
                    <Label className="label-bold-roboto">
                      MENU
                    </Label>
                  </Col>
                  {usr_ajustes === "1" ? <Col className="btn-layout mt-4" xs={3} sm={2} >
                    <NavItem className="text-center">
                      <NavLink tag={RRNavLink} to="/user/configuracion" className="font-w-bold  menu-l">
                        <Label>
                          Ajustes
                        </Label>
                        <img src={SETTING_IMAGE} className="img-btn-layout" alt="..." />
                      </NavLink>
  
                    </NavItem>
                  </Col> : null
  
                  }
                  {usr_factura === "1" ?
                    <Col className="btn-layout mt-4" xs={3} sm={2}>
                      <NavItem className="text-center">
                        <NavLink tag={RRNavLink} to="/user/factura" className="font-w-bold  menu-l">
                          <Label>
                            Factura
                          </Label>
                          <img src={CASH_REGISTER_IMAGE} className="img-btn-layout" alt="..." />
                        </NavLink>
  
  
                      </NavItem>
                    </Col>
                    : null}
                  {
                    usr_inventario === "1" ? <Col className="btn-layout mt-4" xs={3} sm={2}>
                      <NavItem className="text-center">
                        <NavLink tag={RRNavLink} to="/user/inventario" className="font-w-bold  menu-l">
                          <Label>
                            Inventario
                          </Label>
                          <img src={INVENTARIO_IMAGE} className="img-btn-layout" alt="..." />
                        </NavLink>
  
                      </NavItem>
                    </Col> : null
                  }
  
                  {/* <Col className="btn-layout" xs={4} sm={3}>
                  <NavItem className="text-center">
                    <NavLink tag={RRNavLink} to="/user/reporte" className="font-w-bold  menu-l">
                      Ordenes
                    </NavLink>
                  </NavItem>
                </Col> */}
                  {usr_cotizacion === "1" ? <Col className="btn-layout mt-4" xs={3} sm={2}>
                    <NavItem className="text-center">
                      <NavLink tag={RRNavLink} to="/user/cotizacion" className="font-w-bold  menu-l">
                        <Label>
                          Cotizaciones
                        </Label>
                        <img src={COTIZACION_IMAGE} className="img-btn-layout" alt="..." />
                      </NavLink>
  
  
                    </NavItem>
                  </Col> : null}
                  {usr_clientes === "1" ? <Col className="btn-layout mt-4" xs={3} sm={2}>
                    <NavItem className="text-center">
                      <NavLink tag={RRNavLink} to="/user/clientes" className="font-w-bold  menu-l">
                        <Label>
                          Clientes
                        </Label>
  
                        <img src={CLIENTS_IMAGE} className="img-btn-layout" alt="..." />
                      </NavLink>
  
  
                    </NavItem>
                  </Col> : null}
                  {usr_articulo === "1" ? <Col className="btn-layout mt-4" xs={3} sm={2}>
                    <NavItem className="text-center">
                      <NavLink tag={RRNavLink} to="/user/articulos" className="font-w-bold  menu-l">
                        <Label>
                          Articulos
                        </Label>
  
                        <img src={ARTICULOS_IMAGE} className="img-btn-layout" alt="..." />
                      </NavLink>
  
  
                    </NavItem>
                  </Col> : null}
                  {/* {usr_articulo === "1" ? <Col className="btn-layout mt-4" xs={3} sm={2}>
                    <NavItem className="text-center">
                      <NavLink tag={RRNavLink} to="/user/apartado" className="font-w-bold  menu-l">
                        <Label>
                          Apartados
                        </Label>
  
                        <img src={APARTS_IMAGE} className="img-btn-layout" alt="..." />
                      </NavLink>
  
  
                    </NavItem>
                  </Col> : null} */}
                  {
                    usr_inventario === "1" ? <Col className="btn-layout mt-4" xs={3} sm={2}>
                      <NavItem className="text-center">
                        <NavLink tag={RRNavLink} to="/user/reporte" className="font-w-bold  menu-l">
                          <Label>
                            Reportes
                          </Label>
                          <img src={REPORTE_IMAGE} className="img-btn-layout" alt="..." />
                        </NavLink>
  
                      </NavItem>
                    </Col> : null
                  }
                  <Col className="btn-layout mt-4" xs={3} sm={2}>
                    <NavItem
                      className="text-center"
                      onClick={() => {
                        quitToken();
                        // window.location.replace(window.location.host)
                        document.location.href = "/";
                        // window.location = window.location.host;
                      }}
                    >
                      <NavLink className="font-w-bold  menu-l"><Label>SALIR </Label> <img src={EXIT_IMAGE} className="img-btn-layout" alt="..." /></NavLink>
  
                    </NavItem>
                  </Col>
                </Row>
  
              </Nav>
            </Col>
          </Row>
          <Outlet />
          <footer className="footer-default text-center">
            <a href="https://mtechnologypanama.com/home/info" target="_blank" rel="noreferrer"> Desarrollado por wwww.mtechnologypanama.com</a>
          </footer>
        </Container>
  
      </React.Fragment>
    );
  }

};
export default Layout;
