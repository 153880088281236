import { createContext} from 'react';
const StoreContext = createContext({token:"",usr_type:"",usr_id:"",usr_nombre:"",usr_sucursal:"",usr_print:"", usr_ajustes:"",
usr_factura:"",
usr_inventario:"",
usr_cotizacion:"",
usr_precio:"",
usr_articulo:"",
usr_reporte:"",usr_apartado:"",key:"",usr_clientes:"",
     setToken:({token,usr_perfil,usr_id,usr_nombre,usr_sucursal,usr_print, usr_ajustes,usr_factura,usr_inventario,usr_cotizacion,usr_precio,usr_articulo,usr_reporte,usr_apartado,usr_clientes})=>{},quitToken:()=>{}}); // Create a context object

export default StoreContext;