import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import useScanDetection from 'use-scan-detection';
import { Container, Label, Row, Col } from "reactstrap";
import {SEARCH_IMAGE,ADD_IMAGE,EDIT_IMAGE,EXIT_IMAGE,LOGO,PRECIO_IMAGE} from "./../Componentes/Image";
import StoreContext from "./../Componentes/Store/Context";
import {MDBBtn,MDBInput,MDBIcon} from "mdb-react-ui-kit";
import {Button} from '@mui/material';
import { validate } from 'react-email-validator';
import {STATES_FAC_FACTURA} from "../Componentes/States"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ListaClienteModal,ClienteModal,PagarModal,ListaItemModal,EnviadoModal,AnulacionModal,ListaFacModal,ListaCotizacion } from "./../Componentes/Modal";
import {get_caja_n,get_cli_cliente,get_articulo_by_code,get_frm_pago,get_compania,post_cli_cliente,put_cli_cliente,get_list_items,get_cot_cotizacion_list,get_cot_cotizacion_fac,
      get_report_x,print_report_x,get_list_anulacion,get_list_fac_imprimir,get_list_itemsFactura,get_frm_pago_imprimir_fac,} from "../Componentes/Services";
import {FIND_DESC,COPIA_FACTURA,SAVE_FACTURA,ANULAR_FACTURA,PUT_CLIENTE, FOOTER_FACTURA,roundNumber} from "./../Componentes/Util";
import {DATATABLE_FACTURA} from "../Componentes/Datatables";
export const Factura = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ref = useRef(null);
  var enableTab = true;
  var subtotal = 0;
  var itbms = 0;
  var total = 0;
  const { usr_print,usr_id,usr_nombre,usr_precio } = useContext(StoreContext);
  const [error, setError] = useState("");
  const inputUpc =useRef(null);
  const inputCantidad =useRef(null);
  const [caja_num, setCajaNum] = useState("1");
  const [errorCliente, setErrorCliente] = useState("");
  const [errorAnulacion, setErrorAnulacion] = useState("");
  const [error_upc, setError_upc] = useState("");
  const [isBarcode, setBarcode] = useState(false);
  const [isModalPagar, setModalPagar] = useState(false);
  const [isModalAnular, setModalAnular] = useState(false);
  const [isModalCopiaF, setModalCopiaF] = useState(false);
  const [isModalCotizacion, setModalCotizacion] = useState(false);
  const [isModalEnviado, setModalEnviado] = useState(false);
  const [isModalCliente, setModalCliente] = useState(false);
  const [isAddmodalCliente, setModalAddCliente] = useState(false);
  const [isAddmodalItems, setModalItems] = useState(false);
  const [dataCliente, setDataCliente] = useState([]);
  const [dataFormaPago, setFormaPago] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [dataListCopia, setDataListFCopia] = useState([]);
  const [dataFacturaDetalle, setOBJFacturaDetalle] = useState([]);
  const [basicModalEditCliente, setBasicModalEditCliente] = useState(false);
  const [dataListPago, setDatalistpago] = useState([]);
  const [dataListAnulacion, setDatalistAnulacion] = useState([]);
  const [dataListCotizacion, setDataListCotizacion] = useState([]);
  const [formCotizacionDetail, setFormCotizacionDetail] = useState(STATES_FAC_FACTURA({staneName:"formCotizacionDetail"}));
  const [formAnulacion, setFormAnulacion] = useState(STATES_FAC_FACTURA({staneName:"formAnulacion"}));
  const [formListPago, setFormListPago] = useState(STATES_FAC_FACTURA({staneName:"formListPago"}));
  const [dataCompania, setDataCompania] = useState(STATES_FAC_FACTURA({staneName:"dataCompania"}));
  const [formClientes, setFormClientes] = useState(STATES_FAC_FACTURA({staneName:"formClientes"}));
  const [formDropDownCliente, setFormDropDownCliente] = useState(STATES_FAC_FACTURA({staneName:"formDropDownCliente"}));
  const [formDropDownFPago, setFormDropDownFPago] = useState(STATES_FAC_FACTURA({staneName:"formDropDownFPago"}));
  const [formPrecio, setFormPrecio] = useState(
    {
      art_precio: "",
      art_precio2:"",
      art_precio3: "",
    }
  );
  const [formFactura, setFormFactura] = useState(
    {
      fac_id: "",
      fac_identificador:moment(new Date()).format("YYYYMMDDHHmmss"),
      fac_cliente: "",
      fac_vendedor: usr_id,
      fac_fecha:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      fac_caja: "",
      fac_sts: "",
    }
  );
  const [formFacturaDetalle, setFormFacturaDetalle] = useState(STATES_FAC_FACTURA({staneName:"formFacturaDetalle"}));
  useScanDetection({
    onComplete: (code)=>{
      if(document.activeElement !== document.getElementById('art_upc') && document.activeElement !== document.getElementById('fcd_cantidad') && document.activeElement !== document.getElementById('art_descripcion') ){
        if(enableTab===false){
          setTimeout(() => {
            setError_upc("");
            fetchArticuloByUpc({art_upc:code,barcode:true});
            setFormFacturaDetalle({...formFacturaDetalle, art_upc:code});
          }, 300);
  
        }
     }

    },
    minLength: 3 // EAN13
});
  async function fetchCotizacionList() {
    var response = await get_cot_cotizacion_list();
    setDataListCotizacion(response);
    setModalCotizacion(true);
    setFormCotizacionDetail(STATES_FAC_FACTURA({staneName:"formCotizacionDetail"}))
  }

  async function fetchCliente() {
    var response = await get_cli_cliente();
    setDataCliente(response);
    setBasicModalEditCliente(false);
    setModalAddCliente(false);
    setFormClientesBlank()
  }

  async function fetchListFacImprimir() {
    var response = await get_list_fac_imprimir();
    setDataListFCopia(response)
  }

  async function fetchListAnulacion() {
    var response = await get_list_anulacion();
    setDatalistAnulacion(response)
  }

  async function fetchReportX() {
    var response = await get_report_x({fac_fecha:moment(new Date()).format("YYYY-MM-DD"),fac_caja:caja_num,fac_vendedor:usr_id});
    print_report_x(response)
  }

  async function fetch_list_items() {
    var response = await get_list_items();
    setDataItems(response);
  } 

  async function fetchFormaPago() {
    var response = await get_frm_pago();
    setFormaPago(response);
  }

  async function fetchCotizacionByIdentificador({cot_identificador}) {
    var response = await get_cot_cotizacion_fac({cot_identificador});
    setOBJFacturaDetalle(response.items); 
    setFormClientes({
        cli_id: response.cliente[0].cli_id,
        cli_suc: response.cliente[0].cli_suc,
        cli_sts: response.cliente[0].cli_sts,
        cli_nombre: response.cliente[0].cli_nombre,
        cli_direccion:response.cliente[0].cli_direccion,
        cli_telefono: response.cliente[0].cli_telefono,
        cli_telefono2: response.cliente[0].cli_telefono2,
        cli_ruc: response.cliente[0].cli_ruc,
        cli_dv:response.cliente[0].cli_dv,
        cli_email:response.cliente[0].cli_email,
      });
   
    setModalCotizacion(false);
  }

  async function fetchArticuloByUpc({art_upc,barcode}) {
    var response = await get_articulo_by_code(art_upc);
    if (response[0] !== undefined) {
      setFormPrecio({
        art_precio:response[0].art_precio,
        art_precio2:response[0].art_precio2,
        art_precio3:response[0].art_precio3,
      })
      setError_upc("");
      setFormFacturaDetalle({
        ...formFacturaDetalle,
        fcd_articulo: response[0].art_id,
        art_descripcion: response[0].art_descripcion,
        art_upc: response[0].art_upc,
        fcd_precio: response[0].art_precio,
        art_itbm_cod_v:response[0].art_itbm_cod_v,
        art_itbm_venta:response[0].art_itbm_venta,
        fcd_cantidad:"1"
      }); 
      setModalItems(false);
      if(barcode){
        var flag = false;
        var itbms=0;
        var cantidad =0;
        var objItems = dataFacturaDetalle.map((data, i) => {
          if (data.fcd_articulo === response[0].art_id && data.art_descripcion === response[0].art_descripcion) {
            if(response[0].art_precio === data.fcd_precio){
              cantidad = Number(data.fcd_cantidad) + Number(1);
              itbms = (Number(cantidad)*Number(data.fcd_precio))*Number(data.art_itbm_venta);
              flag = true;
              data.fcd_cantidad = Number(cantidad);
              data.fcd_itbms = itbms;
              data.fcd_total = (Number(cantidad)*Number(response[0].art_precio))+Number(itbms);
            }
            return data;
          }
          return data;
        });
  
        if (!flag) {
          itbms = (Number(1)*Number(response[0].art_precio))*Number(response[0].art_itbm_venta);
          setOBJFacturaDetalle([...dataFacturaDetalle, ...[{...{
            ...formFacturaDetalle,
            fcd_articulo: response[0].art_id,
            art_descripcion: response[0].art_descripcion,
            art_upc: response[0].art_upc,
            fcd_precio: response[0].art_precio,
            art_itbm_cod_v:response[0].art_itbm_cod_v,
            art_itbm_venta:response[0].art_itbm_venta,
            fcd_cantidad:"1",
            fcd_itbms:itbms,
            fcd_total:(Number(1)*Number(response[0].art_precio))+(Number(itbms)),
          },

          }]]);
  
        } else {
          setOBJFacturaDetalle(objItems);
        }
        setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle"}));
      setError("");
      }
      else{
        setTimeout(() => {
          inputCantidad.current.focus();
        }, 200);
        
      }
    } else {
      setError_upc("Articulo no existe : "+art_upc);
  
       setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle"}));
    }
  }

  async function fetchCompania() {
    var response = await get_compania();
    var data = response[0];
    setDataCompania({
      bod_suc: data.bod_suc,
      com_nombre: data.com_nombre,
      com_ruc: data.com_ruc,
      com_dv: data.com_dv,
      com_direccion: data.com_direccion,
      com_telefono: data.com_telefono,
      com_telefono2: data.com_telefono2,
      com_email: data.com_email,
      com_fax: data.com_fax,
      com_web: data.com_web,
      com_info_interes:data.com_info_interes
    });
  }
  async function fetchCaja_n() {
    var response = await get_caja_n();
    setCajaNum(response.caja_n)
  }

  useEffect(() => {
    fetchCliente();
    fetchFormaPago();
    fetchCompania();
    fetch_list_items();
    fetchCaja_n();
  }, []);

  const handleSaveRow = ({ exitEditingMode, row, values }) => {
    dataFacturaDetalle[row.index] = values;
    dataFacturaDetalle[row.index] = {
        art_upc:row.original.art_upc,
        art_descripcion: values.art_descripcion,
        art_itbm_venta:row.original.art_itbm_venta,
        fcd_articulo:row.original.fcd_articulo,
        fcd_cantidad: parseFloat(values.fcd_cantidad),
        fcd_itbms: (Number(values.fcd_cantidad)*Number(values.fcd_precio))*Number(row.original.art_itbm_venta),
        fcd_precio: values.fcd_precio,
        fcd_total: (Number(values.fcd_cantidad)*Number(values.fcd_precio))*(1+Number(row.original.art_itbm_venta)),
        fcd_descuento:row.original.fcd_descuento,
        fcd_descuento_number:row.original.fcd_descuento_number,
        art_itbm_cod_v:row.original.art_itbm_cod_v,
      };
      setOBJFacturaDetalle([...dataFacturaDetalle]);
      exitEditingMode();
  };

  const handleSelectCliente = ( row) => {
    setFormClientes(row);
    setModalCliente(false);
  };

  const onChangeDropdown = (selectedOptions) => {
    if(selectedOptions.objName==="cliente"){
      setFormDropDownCliente({
        ...formDropDownCliente,
        cli_id: selectedOptions.value,
        cli_nombre: selectedOptions.label,
        cli_ruc:selectedOptions.ruc,
        cli_email:selectedOptions.email,
        cli_dv:selectedOptions.dv
      });
    }
    else{
      setFormDropDownFPago({
        ...formDropDownFPago,
        frm_id: selectedOptions.value,
        frm_code:selectedOptions.frm_code,
        frm_descripcion: selectedOptions.label
      });
    }
    setError("");
  };

  function onChangeFac_detail(event) {
    const { value, name } = event.target;
      setFormFacturaDetalle({
        ...formFacturaDetalle,
        [name]: value,
      });
      setError("");
      setErrorCliente("")
      setError_upc("");
  }
  function setPrecio(art_precio) {
    setFormFacturaDetalle({
      ...formFacturaDetalle,
      fcd_precio: art_precio,
    }); 
    setAnchorEl(false)
    setTimeout(() => {
      inputCantidad.current.focus();
    }, 200);
  };

  const deleteRow = (row) => {
    var value = dataFacturaDetalle.filter((data, i) => {
      if (i !== row.index) return data;
    });
    setOBJFacturaDetalle(value);
  };
  function keyPressCantidad(event) {
    var flag = false;
    var itbms=0;
    var cantidad =0;
    var objItems = dataFacturaDetalle.map((data, i) => {
      if (data.fcd_articulo === formFacturaDetalle.fcd_articulo && data.art_descripcion === formFacturaDetalle.art_descripcion) {
        if(formFacturaDetalle.fcd_precio === data.fcd_precio){
          cantidad = Number(data.fcd_cantidad) + Number(formFacturaDetalle.fcd_cantidad);
          itbms = (Number(cantidad)*Number(data.fcd_precio))*Number(data.art_itbm_venta);
          flag = true;
          data.fcd_cantidad = Number(cantidad);
          data.fcd_itbms = itbms;
          data.fcd_total = (Number(cantidad)*Number(formFacturaDetalle.fcd_precio))+Number(itbms);
        }
        return data;
      }
      return data;
    });

    if (!flag) {
      itbms = (Number(formFacturaDetalle.fcd_cantidad)*Number(formFacturaDetalle.fcd_precio))*Number(formFacturaDetalle.art_itbm_venta);
      setOBJFacturaDetalle([...dataFacturaDetalle, ...[{...formFacturaDetalle,
        fcd_itbms:itbms,
        fcd_total:(Number(formFacturaDetalle.fcd_cantidad)*Number(formFacturaDetalle.fcd_precio))+(Number(itbms)),
      }]]);

    } else {
      setOBJFacturaDetalle(objItems);
    }
    setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle"}));
  }
  function handleKeyPress(event) {
    const { value, name } = event.target;
        if (event.key === "Enter" && name === "art_upc") {
          if(error_upc.length>0){
            setFormFacturaDetalle({...formFacturaDetalle, art_upc:""});
          }
         
        fetchArticuloByUpc({art_upc:value,barcode:false});
        }
        if (event.key === "Enter" && name === "fcd_cantidad" &&formFacturaDetalle.fcd_cantidad.length<=5 ) {
          keyPressCantidad()
        }
    
    setError("");
  }

  const onClickNuevaFactura = () => {
    var defaultCliente = dataCliente.find((data)=>{
      if(data.cli_id==="16"){
        return data;
      }
    })
    setModalPagar(false);
    setDatalistpago([]);
    setOBJFacturaDetalle([]);
    setFormDropDownCliente(STATES_FAC_FACTURA({staneName:"formDropDownCliente"}));
    setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle"}));
    setFormFactura({
      fac_id: "",
      fac_identificador: moment(new Date()).format("YYYYMMDDHHmmss"),
      fac_cliente: "",
      fac_vendedor: usr_id,
      fac_fecha: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      fac_caja: "",
      fac_sts: "",
    });
    setFormDropDownFPago(STATES_FAC_FACTURA({staneName:"formDropDownFPago"}));
    setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago"}));
    setFormClientes(defaultCliente);
    setError_upc("");
    setError("");
    setErrorAnulacion("");
    setErrorCliente("");
    // setTimeout(() => {
    //   inputUpc.current.focus();
    // }, 1000);
  };
  
  async function selectItemFCopia(data) {
    var response = await get_list_itemsFactura(data.fac_identificador);
    var responseFpago = await get_frm_pago_imprimir_fac(data.fac_identificador);
    COPIA_FACTURA({dataFac:response,dataFpago:responseFpago,dataCompania,dataSelected:data,usr_print})
    setTimeout(() => {
      setModalCopiaF(false)
    }, 200);
  }

  const onClickSaveFactura = () => {
    if (formClientes.cli_id.length > 0 && dataFacturaDetalle[0] !== undefined) {
      SAVE_FACTURA({dataFac:dataFacturaDetalle,dataFpago:dataListPago,dataCompania,formFactura,formDropDownFPago,formClientes,usr_print})
      setModalEnviado(true)
      setModalPagar(false);
        setTimeout(() => {
          setModalEnviado(false);
          onClickNuevaFactura()
        }, 7000);
    } else {
      setError("Campos vacios");
    }
  };

  const onClickAnular = () => {
    var result= ANULAR_FACTURA({dataCompania,formAnulacion,formClientes,usr_print,formDropDownFPago})
     if(!result){
       setErrorAnulacion("Escriba el motivo de la anulación.")
     }
     else{
      setModalAnular(false);
      setErrorAnulacion("")
     }
   };

  const onClickOpenPagar= () => {
    setModalPagar(true);
    setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago"}));
    setFormDropDownFPago({
      frm_id: "",
      frm_code:"",
      frm_descripcion: ""
    });
    setDatalistpago([]);
  }

  const onClickSaveCliente = () => {
    var body = {
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
    };
    if (formClientes.cli_nombre.length > 0) {
      if(formClientes.cli_email.length>0){
        if(validate(formClientes.cli_email)){
          if(FIND_DESC(dataCliente,formClientes.cli_ruc,null)){
            setErrorCliente("Error: Ruc ya esta registrado");
          }
          else{
            post_cli_cliente(body);
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
        else{
          setErrorCliente("E-Mail incorrecto");
        }
      }
      else{
        if(FIND_DESC(dataCliente,formClientes.cli_ruc,null)){
          setErrorCliente("Error: Ruc ya esta registrado");
        }
        else{
          post_cli_cliente(body);
          setTimeout(() => {
            fetchCliente();
          }, 1000);
        }
      }
    } else {
      setErrorCliente("Campos importantes estan vacios");
    }
  };
  
  function onChangeAnulacion(event) {
    const { value, name } = event.target;
    setFormAnulacion({
      ...formAnulacion,
      [name]: value,
    });
    setErrorAnulacion("")
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormClientes({
      ...formClientes,
      [name]: value,
    });
    setErrorCliente("");
  }

  function onChangeFpago(event) {
    const { value, name } = event.target;
    setFormListPago({
      ...formListPago,
      [name]: value,
    });
    setError("");
  }

  const setFormClientesBlank = () => {
    setFormClientes(STATES_FAC_FACTURA({staneName:"formClientes"}))
  }

  const setAddFPago = () => {
    if(formDropDownFPago.frm_code.length>0){
      var flag= false;
      var sum;
     sum = dataListPago.map((data)=>{
        if(data.list_fpago===formDropDownFPago.frm_code){
          flag = true;
          return{
            list_identificador: formFactura.fac_identificador,
            list_fpago: formDropDownFPago.frm_code,
            list_fpagoName: formDropDownFPago.frm_descripcion,
            list_monto: Number(data.list_monto)+Number(formListPago.list_monto),
          };
        }
        else{
          return data
        }
      });
      if(flag == false){
        setDatalistpago([...dataListPago, ...[{
          list_identificador: formFactura.fac_identificador,
          list_fpago: formDropDownFPago.frm_code,
          list_fpagoName: formDropDownFPago.frm_descripcion,
          list_monto: Number(formListPago.list_monto),
        }]]);
      }
      else{
        setDatalistpago(sum)
      }

      setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago"}))
      setFormDropDownFPago({
        frm_id: "",
        frm_code:"",
        frm_descripcion: ""
      })
    }
    else{
      setError("Selecciona la forma de pago");
    }
  }
  
  const deleteRowFpago = (row) => {
      var value = dataListPago.filter((data, i) => {
        if (i !== row.index) return data;
      });
      setDatalistpago(value);
  };
  
  const onClickUpdateClient = () => {
    if (formClientes.cli_nombre.length > 0) { 
      if(formClientes.cli_email.length>0){
        if(validate(formClientes.cli_email)){
          if(FIND_DESC(dataCliente,formClientes.cli_ruc,formClientes.cli_id)){
            setErrorCliente("Error: Ruc ya esta registrado");
          }
          else{
            PUT_CLIENTE({formClientes})
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
        else{
          setErrorCliente("E-Mail incorrecto");
        }
      } else {
        if(FIND_DESC(dataCliente,formClientes.cli_ruc,formClientes.cli_id)){
          setErrorCliente("Error: Ruc ya esta registrado");
        }
        else{
          PUT_CLIENTE({formClientes})
          setTimeout(() => {
            fetchCliente();
          }, 1000);
        }
      }
      }
      else{
        setErrorCliente("Campos importantes estan vacios");
      }
  };

  var ObjClientes = [];
  dataCliente.map((item) => {
    ObjClientes.push({
      value: item.cli_id,
      label: item.cli_nombre + " " + item.cli_ruc,
      ruc: item.cli_ruc,
      email: item.cli_email,
      dv:item.cli_dv,
      objName: "cliente",
    });
  });

  var ObjFrmPago = [];
  dataFormaPago.map((item) => {
    ObjFrmPago.push({
      value: item.frm_id,
      frm_code:item.frm_code,
      label: item.frm_descripcion,
      objName: "frmPago",
    });
  });

  if (formClientes.cli_nombre.length > 0) {
    enableTab = false;
  }

  dataFacturaDetalle.map((row) => {
    subtotal = Number(subtotal) + Number(Number(row.fcd_cantidad) * Number(row.fcd_precio));
    itbms = Number(itbms) + roundNumber(Number(row.fcd_itbms),3);
    total =   Number(subtotal) + Number(itbms);
  });
  subtotal = roundNumber(subtotal,2);
  itbms = roundNumber(itbms,2);
  total = roundNumber(total,2);
  return (
    <Container className="bg-light border border-0" fluid>
      {isModalEnviado && (
        <EnviadoModal
        isOpen={isModalEnviado}
        toggle={setModalEnviado}
        dataListpago={dataListPago}
        total ={total}
        />
      )}
      {isModalCliente && (
        <ListaClienteModal
        dataCliente={dataCliente}
        isOpen={isModalCliente}
        toggle={setModalCliente}
        selectCliente={handleSelectCliente}
        />
      )}
      {isAddmodalCliente && (
        <ClienteModal
          props={formClientes}
          isOpen={isAddmodalCliente}
          basicModalEdit={basicModalEditCliente}
          toggle={setModalAddCliente}
          toggleSave={onClickSaveCliente}
          toggleSaveEdit={onClickUpdateClient}
          onChange={onChange}
          error={errorCliente}
          setFormClientesBlank={setFormClientesBlank}
        />
      )}
      {isModalAnular && (
        <AnulacionModal
          dataListAnulacion={dataListAnulacion}
          formAnulacion={formAnulacion}
          isOpen={isModalAnular}
          toggle={setModalAnular}
          onChange={onChangeAnulacion}
          setFormAnulacion={setFormAnulacion}
          toggleSave={onClickAnular}
          errorAnulacion={errorAnulacion}
        />
      )}
      {isAddmodalItems && (
        <ListaItemModal
          props={dataItems}
          isOpen={isAddmodalItems}
          toggle={setModalItems}
          selectItem={fetchArticuloByUpc}
        />
      )}
      {isModalCotizacion && (
        <ListaCotizacion
          dataListCotizacion={dataListCotizacion}
          isOpen={isModalCotizacion}
          toggle={setModalCotizacion}
          selectItemCotizacion={fetchCotizacionByIdentificador}
        />
      )}
      {isModalCopiaF && (
        <ListaFacModal
          dataListCopia={dataListCopia}
          isOpen={isModalCopiaF}
          toggle={setModalCopiaF}
          selectItemFCopia={selectItemFCopia}
        />
      )}
      {isModalPagar && (
        <PagarModal
          deleteRow={deleteRowFpago}
          setDatalistpago={setDatalistpago}
          formDropDownFPago={formDropDownFPago}
          setAddFPago ={setAddFPago}
          onChangeDropdown={onChangeDropdown}
          dataListpago={dataListPago}
          formListPago={formListPago}
          ObjFrmPago={ObjFrmPago}
          isOpen={isModalPagar}
          toggle={setModalPagar}
          toggleSave={onClickSaveFactura}
          onChange={onChangeFpago}
          total={total}
          error={error}
        />
      )}
      <Row className="mt-2">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">FACTURA</Label>
        </Col>
        <Col xs={5}>
          <MDBInput
            id="fac_vendedor"
            wrapperClass="mb-3"
            label="Vendedor"
            name="fac_vendedor"
            defaultValue={usr_nombre}
            disabled={true}
          />
        </Col>
        <Col xs={7}>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>fetchReportX()}>Informe X</Button>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{setModalAnular(true); fetchListAnulacion(); setErrorAnulacion("")}}>Anulación</Button>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{setModalCopiaF(true);fetchListFacImprimir()}}>Copia</Button>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{fetchCotizacionList()}}>Cotizaciones</Button>
        <Button className=" btn-reporte_x button-color-gray" variant="contained" disabled={true}>Apartados</Button>
        <img src={LOGO} className="logo-factura" alt="..." />
        </Col>
        <Col xs={6} className="d-flex">
        <MDBInput
            id="cli_ruc"
            wrapperClass="mb-3"
            label="CLIENTE"
            name="cli_ruc"
            value={formClientes.cli_nombre + " " +formClientes.cli_dv}
            disabled={true}
          />
          <img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setModalCliente(true);setErrorCliente("");
          setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle"}));}}/>
          <img src={ADD_IMAGE} className="src-image" alt="..." onClick={()=>{setModalAddCliente(true);setErrorCliente("");setFormClientes(STATES_FAC_FACTURA({staneName:"formClientes"}))}}/>
          {formClientes.cli_nombre.length>0 && formClientes.cli_nombre !=="CONSUMIDOR FINAL"?<img src={EDIT_IMAGE} className="src-image" alt="..." onClick={()=>{setModalAddCliente(true);;setErrorCliente("");setBasicModalEditCliente(true)}}/>:null}
        </Col>
        <Col xs={6}>
        <MDBInput
            id="cli_email"
            wrapperClass="mb-3"
            label="EMAIL"
            name="cli_email"
            value={formClientes.cli_email}
            disabled={true}
          />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={2} className="d-flex col-factura-items">
              <MDBInput
                id="art_upc"
                className="height-37"
                label="Código"
                name="art_upc"
                onChange={onChangeFac_detail}
                disabled={enableTab}
                value={formFacturaDetalle.art_upc}
                ref={inputUpc}
          
                onKeyDown={(e) => {
                  handleKeyPress(e, formFactura.fcd_articulo);
                }}
              />
                  {formClientes.cli_nombre.length>0?<img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setModalItems(true)}}/>:null}
                  {formClientes.cli_nombre.length>0 && formFacturaDetalle.art_descripcion.length>0?<img src={EXIT_IMAGE} className="src-image" alt="..." 
                  onClick={()=>{      setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle"}));}}/>:null}
            </Col>
            <Col xs={6} className="col-factura-items">
              <MDBInput
                id="art_descripcion"
                label="Descripción artículo"
                name="art_descripcion"
                onChange={onChangeFac_detail}
                value={formFacturaDetalle.art_descripcion}
                disabled={formFacturaDetalle.fcd_articulo.length>0  && usr_precio ==="1"?false:true}
              />
            </Col>
            <Col xs={2} className="col-factura-items d-flex">
              <MDBInput
                id="fcd_precio"
                label="Precio"
                name="fcd_precio"
                type={"number"}
                min={"1"}
                value={formFacturaDetalle.fcd_precio}
                onChange={onChangeFac_detail}
                disabled={formFacturaDetalle.fcd_articulo.length>0 && usr_precio ==="1"?false:true}
              />
               
                    <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled ={formFacturaDetalle.art_upc.length>0  ?false:true}
      >
      <img src={PRECIO_IMAGE} className="src-image style-precio-btn" alt="..." 
       />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>setPrecio(formPrecio.art_precio)}>{formPrecio.art_precio}</MenuItem>
        <MenuItem onClick={()=>setPrecio(formPrecio.art_precio2)}>{formPrecio.art_precio2}</MenuItem>
        <MenuItem onClick={()=>setPrecio(formPrecio.art_precio3)}>{formPrecio.art_precio3}</MenuItem>
      </Menu>
            </Col>
            <Col xs={2} className="col-factura-items d-flex">
              <MDBInput
                id="fcd_cantidad"
                label="Cantidad"
                name="fcd_cantidad"
                ref={inputCantidad}
                type={"number"}
                min={"1"}
                max={10000}
                // ref={ref}
                step='0.01'
                disabled ={formFacturaDetalle.art_descripcion.length>0  ?false:true}
                onChange={onChangeFac_detail}
                value={formFacturaDetalle.fcd_cantidad}
                onKeyDown={(e) => handleKeyPress(e, formFacturaDetalle.fcd_cantidad)}
              />
              {formFacturaDetalle.fcd_cantidad !=="0"?<img src={ADD_IMAGE} className="src-image" alt="..." 
                  onClick={()=>{keyPressCantidad();}}/>:<img src={ADD_IMAGE} className="src-image" alt="..." />}
              
            </Col>
            <Col xs={8}>
              <Label className="error-label">{error_upc.length > 0 ? error_upc : "="}</Label>
              <Label className="error-label">{error.length > 0 && isAddmodalCliente === false ? <>{" * "+ error}</> : ""}</Label>
            </Col>
            <Col xs={4} className="mb-2">
              <Row>
                <Col xs={4}> 
                  <MDBBtn onClick={() => ( onClickNuevaFactura())} className="d-flex float-end" color="success">
                    <MDBIcon fas icon="plus-circle" className="me-1 class-btn-factura" /> Nuevo
                  </MDBBtn>
                </Col>
                <Col xs={4}>  
                  <MDBBtn className="d-flex float-end btn-cancelar-position" disabled ={formClientes.cli_nombre.length>0?false:true} onClick={() => ( onClickNuevaFactura())} color="danger">
                    <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex" />  Cancelar
                  </MDBBtn></Col>
                <Col xs={4}>   
                  <MDBBtn className="d-flex float-end"  disabled ={dataFacturaDetalle[0] != undefined ?false:true} onClick={() =>  (dataFacturaDetalle[0] != undefined ? onClickOpenPagar():null) }>
                    <MDBIcon fas icon="credit-card" className="me-1 class-btn-factura d-flex"  />     Pagar
                  </MDBBtn>
                </Col>
              </Row>
            </Col>
            <Col className="modal-add-inventario datatable-factura">
              <DATATABLE_FACTURA deleteRow={deleteRow} handleSaveRow={handleSaveRow} dataFacturaDetalle={dataFacturaDetalle} enableTab={enableTab} usr_precio={usr_precio}/>
            </Col>
          </Row>
          <Row className="mt-3">
            <FOOTER_FACTURA enableTab = {enableTab} subtotal = {subtotal} itbms = {itbms} total ={total}/>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};