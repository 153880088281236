import React, { useState, useEffect, useContext, useMemo } from "react";
import { res, validate } from 'react-email-validator';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "./../Componentes/www/style.css";
import {DATATABLE_CATEGORIAS,DATATABLE_USUARIOS,DATATABLE_MARCAS,DATATABLE_PROVEEDORES,DATATABLE_BODEGAS,DATATABLE_CLIENTES} from "../Componentes/Datatables";
import {FIND_DESC} from "./../Componentes/Util";
import {
  BodegaModal,
  ClienteModal,
  ProveedorModal,
  CategoriaModal,
  MarcaModal,
  UsuariosModal,
  DeleteModal
} from "./../Componentes/Modal";
import StoreContext from "./../Componentes/Store/Context";
import {
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTextArea 
} from "mdb-react-ui-kit";

import {
  get_compania,
  put_compania,
  get_bod_bodega,
  post_bod_bodega,
  put_bod_bodega_sts,
  put_bod_bodega,
  get_cli_cliente,
  post_cli_cliente,
  put_cli_cliente,
  put_cli_cliente_sts,
  post_pro_proveedor,
  get_pro_proveedor,
  put_pro_proveedor,
  put_pro_proveedor_sts,
  get_cat_categoria,
  post_cat_categoria,
  put_cat_categoria,
  put_cat_categoria_sts,
  get_mar_marca,
  post_mar_marca,
  put_mar_marca,
  put_mar_marca_sts,
  get_usr_usuario,
  post_usr_usuario,
  put_usr_usuario,
  put_usr_usuario_sts,
} from "../Componentes/Services";
import { Container, Label, Row, Col } from "reactstrap";
export const Configuracion = () => {
  const [iconsActive, setIconsActive] = useState("tab1");

  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  return (
    <>
      <MDBTabs className="mb-3">
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab1")} active={iconsActive === "tab1"}>
            <MDBIcon fas icon="building" className="me-2" /> Compañia
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab2")} active={iconsActive === "tab2"}>
            <MDBIcon fas icon="warehouse" className="me-2" /> Bodegas
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab3")} active={iconsActive === "tab3"}>
            <MDBIcon fas icon="users" className="me-2" /> Clientes
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab4")} active={iconsActive === "tab4"}>
            <MDBIcon fas icon="people-carry" className="me-2" /> Proveedores
          </MDBTabsLink>
        </MDBTabsItem>
        {/* <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab5")} active={iconsActive === "tab5"}>
            <MDBIcon fas icon="cash-register" className="me-2" /> Cajas
          </MDBTabsLink>
        </MDBTabsItem> */}
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab5")} active={iconsActive === "tab5"}>
            <MDBIcon fas icon="layer-group" className="me-2" /> Categorias
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab6")} active={iconsActive === "tab6"}>
            <MDBIcon fas icon="code-branch" className="me-2" /> Marcas
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab7")} active={iconsActive === "tab7"}>
            <MDBIcon fas icon="file-invoice" className="me-2" /> Usuarios
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={iconsActive === "tab1"}>
          <Compania />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab2"}>
          <Bodegas />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab3"}>
          <Clientes />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab4"}>
          <Proveedores />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab5"}>
          <Categorias />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab6"}>
          <Marcas />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab7"}>
          <Usuarios />
        </MDBTabsPane>
      </MDBTabsContent>
    </>
  );
};
function Compania() {
  const [dataCompania, setDataCompania] = useState({
    bod_suc: "",
    com_nombre: "",
    com_ruc: "",
    com_dv: "",
    com_direccion: "",
    com_telefono: "",
    com_telefono2: "",
    com_email: "",
    com_fax: "",
    com_web: "",
    com_info_interes:"",
    com_cotizacion_info:""
  });
  const onClickUpdateCompania = () => {
    var body = {
      bod_suc: dataCompania.bod_suc,
      com_nombre: dataCompania.com_nombre,
      com_ruc: dataCompania.com_ruc,
      com_dv: dataCompania.com_dv,
      com_direccion: dataCompania.com_direccion,
      com_telefono: dataCompania.com_telefono,
      com_telefono2: dataCompania.com_telefono2,
      com_email: dataCompania.com_email,
      com_fax: dataCompania.com_fax,
      com_web: dataCompania.com_web,
      com_info_interes:dataCompania.com_info_interes,
      com_cotizacion_info:dataCompania.com_cotizacion_info
    };

    put_compania(body);
    setTimeout(() => {
      fetchCompania();
    }, 1000);
  };

  async function fetchCompania() {
    var response;
    response = await get_compania();
    var data = response[0];
    setDataCompania({
      bod_suc: data.bod_suc,
      com_nombre: data.com_nombre,
      com_ruc: data.com_ruc,
      com_dv: data.com_dv,
      com_direccion: data.com_direccion,
      com_telefono: data.com_telefono,
      com_telefono2: data.com_telefono2,
      com_email: data.com_email,
      com_fax: data.com_fax,
      com_web: data.com_web,
      com_info_interes:data.com_info_interes,
      com_cotizacion_info:data.com_cotizacion_info
    });
  }

  function onChange(event) {
    const { value, name } = event.target;
    setDataCompania({
      ...dataCompania,
      [name]: value,
    });
  }

  useEffect(() => {
    fetchCompania();
  }, []);
  var ar =[
    {id:"1"},
    {id:"2"},
  ]

  return (
    <Container>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            id="com_nombre"
            name="com_nombre"
            label="Nombre"
            onChange={onChange}
            value={dataCompania.com_nombre}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_ruc"
            name="com_ruc"
            label="RUC"
            onChange={onChange}
            value={dataCompania.com_ruc}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_dv"
            name="com_dv"
            label="DV"
            onChange={onChange}
            value={dataCompania.com_dv}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_web"
            name="com_web"
            label="Pagina Web"
            onChange={onChange}
            value={dataCompania.com_web}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            id="com_direccion"
            name="com_direccion"
            label="Dirección"
            onChange={onChange}
            value={dataCompania.com_direccion}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_telefono"
            name="com_telefono"
            label="Telefono"
            onChange={onChange}
            value={dataCompania.com_telefono}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_telefono2"
            name="com_telefono2"
            label="Telefono 2"
            onChange={onChange}
            value={dataCompania.com_telefono2}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_fax"
            name="com_fax"
            label="FAX"
            onChange={onChange}
            value={dataCompania.com_fax}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            id="com_email"
            name="com_email"
            label="EMAIL"
            onChange={onChange}
            value={dataCompania.com_email}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBTextArea 
            id="com_info_interes"
            name="com_info_interes"
            label="Informacion de interes en la factura"
            rows={8}
            onChange={onChange}
            value={dataCompania.com_info_interes}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBTextArea 
            id="com_cotizacion_info"
            name="com_cotizacion_info"
            label="Informacion de interes en la cotizaciones"
            rows={8}
            onChange={onChange}
            value={dataCompania.com_cotizacion_info}
          />
        </MDBCol>
      </MDBRow>
      <Row>
        <Col xs={5}></Col>
        <Col xs={2}>
          <MDBBtn className="mb-4" type="submit" block onClick={() => onClickUpdateCompania()}>
          <MDBIcon fas icon="save" className="me-1" /> Guardar
          </MDBBtn>
        </Col>
        <Col xs={5}></Col>
      </Row>
    </Container>
  );
}
function Bodegas() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingB, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { usr_sucursal } = useContext(StoreContext);
  const [dataBodegas, setDataBodegas] = useState([]);
  const [formBodegas, setFormBodegas] = useState({
    bod_id: "",
    bod_nombre: "",
    bod_ubicacion: "",
    bod_sts: "",
  });

  const onClickUpdateBodega = () => {
    var body = {
      bod_id: formBodegas.bod_id,
      bod_suc: formBodegas.bod_suc,
      bod_nombre: formBodegas.bod_nombre,
      bod_ubicacion: formBodegas.bod_ubicacion,
    };

    put_bod_bodega(body);
    setTimeout(() => {
      fetchBodega();
    }, 1000);
  };

  const onClickUpdateBodega_sts = () => {
    var body = {
      bod_id: formBodegas.bod_id,
      bod_sts: 0,
    };

    put_bod_bodega_sts(body);
    setTimeout(() => {
      fetchBodega();
    }, 1000);
  };

  const onClickSaveBodega = () => {
    var body = {
      suc_id: usr_sucursal,
      bod_nombre: formBodegas.bod_nombre,
      bod_ubicacion: formBodegas.bod_ubicacion,
    };
    if (formBodegas.bod_nombre.length > 0) {
      post_bod_bodega(body);
      setTimeout(() => {
        fetchBodega();
      }, 1000);
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchBodega() {
    setLoading(true);
    var response;
    response = await get_bod_bodega();
    setDataBodegas(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormBodegas({
      ...formBodegas,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchBodega();
  }, []);

  return (
    <Container>
      {basicModal && (
        <BodegaModal
          props={formBodegas}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveBodega}
          toggleSaveEdit={onClickUpdateBodega}
          onChange={onChange}
          error={error}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formBodegas.bod_nombre}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateBodega_sts}
          text={"Eliminar este registro?"}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_BODEGAS dataBodegas={dataBodegas} setBasicModalDelete={setBasicModalDelete} setFormBodegas={setFormBodegas} setBasicModal={setBasicModal} setBasicModalEdit={setBasicModalEdit} setError={setError} fetchBodega={fetchBodega}/>
        </Col>
      </Row>
    </Container>
  );
}
function Clientes() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingC, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [dataClientes, setDataClientes] = useState([]);
  const [formClientes, setFormClientes] = useState({
    cli_id: "",
    cli_suc: "",
    cli_sts: "",
    cli_nombre: "",
    cli_direccion: "",
    cli_telefono: "",
    cli_telefono2: "",
    cli_ruc: "",
    cli_dv: "",
    cli_email: "",
  });
  
  const setFormClientesBlank = () => {
    setFormClientes({
      cli_id: "",
      cli_suc: "",
      cli_sts: "",
      cli_nombre: "",
      cli_direccion: "",
      cli_telefono: "",
      cli_telefono2: "",
      cli_ruc: "",
      cli_dv: "",
      cli_email: "",
      })
      }
  const onClickUpdateClient = () => {
    var body = {
      cli_id: formClientes.cli_id,
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
    };
    if (formClientes.cli_nombre.length > 0) {
      if(formClientes.cli_email.length>0){
        if(validate(formClientes.cli_email)){
          if(FIND_DESC(dataClientes,formClientes.cli_ruc,formClientes.cli_id)){
            setError("Error: Ruc ya esta registrado");
          }
          else{
            put_cli_cliente(body);
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
        else{
          setError("E-Mail incorrecto");
        }
      }
      else{
        if(FIND_DESC(dataClientes,formClientes.cli_ruc,formClientes.cli_id)){
          setError("Error: Ruc ya esta registrado");
        }
        else{
          put_cli_cliente(body);
          setTimeout(() => {
            fetchCliente();
          }, 1000);
        }
      }

    } else {
      setError("Campos importantes estan vacios");
    }
  };

  const onClickUpdateCliente_sts = () => {
    var body = {
      cli_id: formClientes.cli_id,
      cli_sts: 0,
    };

    put_cli_cliente_sts(body);
    setTimeout(() => {
      fetchCliente();
    }, 1000);
  };

  const onClickSaveCliente = () => {
    var body = {
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
    };
    if (formClientes.cli_nombre.length > 0) {
      if(formClientes.cli_email.length>0){
        if(validate(formClientes.cli_email)){
          if(FIND_DESC(dataClientes,formClientes.cli_ruc,null)){
            setError("Error: Ruc ya esta registrado");
          }
          else{
            post_cli_cliente(body);
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
        else{
          setError("E-Mail incorrecto");
        }
      }
      else{
        if(FIND_DESC(dataClientes,formClientes.cli_ruc,null)){
          setError("Error: Ruc ya esta registrado");
        }
        else{
          post_cli_cliente(body);
          setTimeout(() => {
            fetchCliente();
          }, 1000);
        }
      }

    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchCliente() {
    setLoading(true);
    var response;
    response = await get_cli_cliente();
    setDataClientes(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormClientes({
      ...formClientes,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchCliente();
  }, []);

  return (
    <Container>
      {basicModal && (
        <ClienteModal
          props={formClientes}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveCliente}
          toggleSaveEdit={onClickUpdateClient}
          onChange={onChange}
          error={error}
          setFormClientesBlank={setFormClientesBlank}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formClientes.cli_nombre+" "+ formClientes.cli_ruc+" "+formClientes.cli_dv}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateCliente_sts}
          text={"Eliminar este registro?"}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
          <DATATABLE_CLIENTES dataClientes={dataClientes} 
            setBasicModalDelete={setBasicModalDelete} 
            setFormClientes={setFormClientes} setBasicModal={setBasicModal}
           setBasicModalEdit={setBasicModalEdit} setError={setError} fetchBodega={fetchCliente} isLoadingC ={isLoadingC}/>
        </Col>
      </Row>
    </Container>
  );
}
function Proveedores() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingP, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataProveedores, setDataProveedores] = useState([]);
  const [formProveedores, setFormProveedores] = useState({
    prov_id: "",
    prov_suc: "",
    prov_nombre: "",
    prov_direccion: "",
    prov_telefono: "",
    prov_ruc: "",
    prov_dv: "",
    prov_sts: "",
    prov_email: "",
  });

  const onClickUpdateProveedores = () => {
    var body = {
      prov_id: formProveedores.prov_id,
      prov_nombre: formProveedores.prov_nombre,
      prov_direccion: formProveedores.prov_direccion,
      prov_telefono: formProveedores.prov_telefono,
      prov_ruc: formProveedores.prov_ruc,
      prov_dv: formProveedores.prov_dv,
      prov_email: formProveedores.prov_email,
    };
    if(validate(formProveedores.prov_email)){
      if (formProveedores.prov_nombre.length > 0) {
        if(FIND_DESC(dataProveedores,formProveedores.prov_ruc,formProveedores.prov_id)){
          setError("Error: Ruc ya esta registrado");
        }
        else{
          put_pro_proveedor(body);
          setTimeout(() => {
            fetchProveedores();
          }, 1000);
        }
      } else {
        setError("Campos importantes estan vacios");
      }
    }
    else{
      setError("E-Mail incorrecto");
    }

  };

  const onClickUpdateProveedor_sts = () => {
    var body = {
      prov_id: formProveedores.prov_id,
      prov_sts: 0,
    };

    put_pro_proveedor_sts(body);
    setTimeout(() => {
      fetchProveedores();
    }, 1000);
  };

  const onClickSaveProveedor = () => {
    var body = {
      prov_nombre: formProveedores.prov_nombre,
      prov_direccion: formProveedores.prov_direccion,
      prov_telefono: formProveedores.prov_telefono,
      prov_ruc: formProveedores.prov_ruc,
      prov_dv: formProveedores.prov_dv,
      prov_email: formProveedores.prov_email,
    };
    if(validate(formProveedores.prov_email)){
      if (formProveedores.prov_nombre.length > 0) {
        if(FIND_DESC(dataProveedores,formProveedores.prov_ruc,null)){
          setError("Error: Ruc ya esta registrado");
        }
        else{
          post_pro_proveedor(body);
          setTimeout(() => {
            fetchProveedores();
          }, 1000);
        }
      } else {
        setError("Campos importantes estan vacios");
      }
    }
    else{
      setError("E-Mail incorrecto");
    }

  };

  async function fetchProveedores() {
    setLoading(true);
    var response;
    response = await get_pro_proveedor();
    setDataProveedores(response);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setBasicModal(false);
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormProveedores({
      ...formProveedores,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchProveedores();
  }, []);

  return (
    <Container>
      {basicModal && (
        <ProveedorModal
          props={formProveedores}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveProveedor}
          toggleSaveEdit={onClickUpdateProveedores}
          onChange={onChange}
          error={error}
        />
      )}
            {basicModalDelete && (
        <DeleteModal
          props={formProveedores.prov_nombre+" "+formProveedores.prov_ruc+" "+formProveedores.prov_dv}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateProveedor_sts}
          text={"Eliminar este registro?"}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_PROVEEDORES dataProveedores={dataProveedores} 
            setBasicModalDelete={setBasicModalDelete} 
            setFormProveedores={setFormProveedores} setBasicModal={setBasicModal}
           setBasicModalEdit={setBasicModalEdit} setError={setError} fetchProveedores={fetchProveedores} isLoadingP ={isLoadingP}/>

        </Col>
      </Row>
    </Container>
  );
}
function Categorias() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingCa, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataCategorias, setDataCategorias] = useState([]);
  const [formCategorias, setFormCategorias] = useState({
    cat_id: "",
    cat_descripcion: "",
    cat_sts: "",
  });

  const onClickUpdateCategorias = () => {
    var body = {
      cat_id: formCategorias.cat_id,
      cat_descripcion: formCategorias.cat_descripcion,
    };

    put_cat_categoria(body);
    setTimeout(() => {
      fetchCategoria();
    }, 1000);
  };

  const onClickUpdateCategorias_sts = () => {
    var body = {
      cat_id: formCategorias.cat_id,
      cat_sts: 0,
    };

    put_cat_categoria_sts(body);
    setTimeout(() => {
      fetchCategoria();
    }, 1000);
  };

  const onClickSaveCategorias = () => {
    var body = {
      cat_descripcion: formCategorias.cat_descripcion,
    };
    if (formCategorias.cat_descripcion.length > 0) {
      post_cat_categoria(body);
      setTimeout(() => {
        fetchCategoria();
      }, 1000);
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchCategoria() {
    setLoading(true);
    var response;
    response = await get_cat_categoria();
    setDataCategorias(response);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setBasicModal(false);
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormCategorias({
      ...formCategorias,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchCategoria();
  }, []);

  return (
    <Container>
      {basicModal && (
        <CategoriaModal
          props={formCategorias}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveCategorias}
          toggleSaveEdit={onClickUpdateCategorias}
          onChange={onChange}
          error={error}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formCategorias.cat_descripcion}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateCategorias_sts}
          text={"Eliminar este registro?"}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_CATEGORIAS
        dataCategorias={dataCategorias} 
        setBasicModalDelete={setBasicModalDelete} 
        setFormCategorias={setFormCategorias} 
        setBasicModal={setBasicModal} setBasicModalEdit={setBasicModalEdit}
        setError={setError} fetchCategoria={fetchCategoria}isLoadingCa={isLoadingCa}
        />
        </Col>
      </Row>
    </Container>
  );
}
function Marcas() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [isLoadingM, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataMarcas, setDataMarcas] = useState([]);
  const [formMarcas, setFormMarcas] = useState({
    mar_id: "",
    mar_descripcion: "",
    mar_sts: "",
  });

  const onClickUpdateMarcas = () => {
    var body = {
      mar_id: formMarcas.mar_id,
      mar_descripcion: formMarcas.mar_descripcion,
    };

    put_mar_marca(body);
    setTimeout(() => {
      fetchMarcas();
    }, 1000);
  };

  const onClickUpdateMarcas_sts = () => {
    var body = {
      mar_id: formMarcas.mar_id,
      mar_sts: 0,
    };

    put_mar_marca_sts(body);
    setTimeout(() => {
      fetchMarcas();
    }, 1000);
  };

  const onClickSaveMarcas = () => {
    var body = {
      mar_descripcion: formMarcas.mar_descripcion,
    };
    if (formMarcas.mar_descripcion.length > 0) {
      post_mar_marca(body);
      setTimeout(() => {
        fetchMarcas();
      }, 1000);
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchMarcas() {
    setLoading(true);
    var response;
    response = await get_mar_marca();
    setDataMarcas(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormMarcas({
      ...formMarcas,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchMarcas();
  }, []);

  return (
    <Container>
      {basicModal && (
        <MarcaModal
          props={formMarcas}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveMarcas}
          toggleSaveEdit={onClickUpdateMarcas}
          onChange={onChange}
          error={error}
        />
      )}
            {basicModalDelete && (
        <DeleteModal
          props={formMarcas.mar_descripcion}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateMarcas_sts}
          text={"Eliminar este registro?"}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_MARCAS dataMarcas ={dataMarcas} 
        setBasicModalDelete={setBasicModalDelete}
        setFormMarcas={setFormMarcas}
        setBasicModal={setBasicModal}
        setBasicModalEdit={setBasicModalEdit}
        setError={setError}
        fetchMarcas={fetchMarcas}
        isLoadingM={isLoadingM} />
        </Col>
      </Row>
    </Container>
  );
}
function Usuarios() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingU, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataUsuarios, setDataUsuarios] = useState([]);
  const [formUsuarios, setFormUsuarios] = useState({
    usr_id: "",
    usr_usuario: "",
    usr_clave: "",
    usr_nombre: "",
    usr_perfil: "",
    usr_sucursal: "",
    usr_print:"",
    usr_ajustes:"",
    usr_factura:"",
    usr_inventario:"",
    usr_cotizacion:"",
    usr_articulo:"",
    usr_reporte:"",
    usr_apartado:"",
    usr_clientes:"",
    usr_precio:""
  });

  const onClickUpdateUsuarios = () => {
    put_usr_usuario(formUsuarios);
    setTimeout(() => {
      fetchUsuarios();
    }, 1000);
  };

  const onClickUpdateUsuario_sts = () => {
    var body = {
      usr_id: formUsuarios.usr_id,
      usr_sts: 0,
    };

    put_usr_usuario_sts(body);
    setTimeout(() => {
      fetchUsuarios();
    }, 1000);
  };

  const onClickSaveUsuarios = () => {

    if (
      formUsuarios.usr_usuario.length > 0 &&
      formUsuarios.usr_clave.length > 0 &&
      formUsuarios.usr_nombre.length > 0 &&
      formUsuarios.usr_print.length >0
    ) {
      post_usr_usuario(formUsuarios);
      setTimeout(() => {
        fetchUsuarios();
      }, 1000);
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchUsuarios() {
    setLoading(true);
    var response;
    response = await get_usr_usuario();
    setDataUsuarios(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormUsuarios({
      ...formUsuarios,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const onChangeDropdown = (selectedOptions) => {
    if(selectedOptions.objName==="print"){
      setFormUsuarios({
        ...formUsuarios,
        usr_print:selectedOptions.value
    })
    }
    else{
      setFormUsuarios({
        ...formUsuarios,
        usr_perfil:selectedOptions.value
    })
    }
    };

    const handleOnChangeCheckBox = (event) => {
      const { value, name } = event.target;
      setFormUsuarios({
        ...formUsuarios,
        [name]:value==="0"?"1":"0"
    })
    };
  return (
    <Container>
      {basicModal && (
        <UsuariosModal
          props={formUsuarios}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveUsuarios}
          toggleSaveEdit={onClickUpdateUsuarios}
          onChange={onChange}
          error={error}
          onChangeDropdown={onChangeDropdown}
          handleOnChangeCheckBox={handleOnChangeCheckBox}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formUsuarios.usr_nombre}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateUsuario_sts}
          text={"Eliminar este registro?"}
        />
      )}
      <Row>
      <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_USUARIOS dataUsuarios ={dataUsuarios} 
        setBasicModalDelete={setBasicModalDelete}
        setFormUsuarios={setFormUsuarios}
        setBasicModal={setBasicModal}
        setBasicModalEdit={setBasicModalEdit}
        setError={setError}
        fetchUsuarios={fetchUsuarios}
        isLoadingU={isLoadingU} />
        </Col>
      </Row>
    </Container>
  );
}
